import React, { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { useAlert } from "react-alert";
import { AppContext } from '../Context'
import { useAccount, useMsal } from '@azure/msal-react'
import { UserProfile } from '../../_models/common/profile.model'
import baseApi from '../../_api/base.api';
import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@material-ui/core";
import { MenuProps, options } from "./util";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "4px"
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

interface Props {
  userProfileData: any
}
export default function AnnualGraduationPartyForm({ userProfileData }: Props) {

  //console.log(userProfileData);

  const { accounts } = useMsal();
  const alert = useAlert();
  const classes = useStyles();
  const account = useAccount(accounts[0] || {});
  //const [userData, setUserData] = useState<AnnualGraduationPartyModel>(registeration);
  //const [onPremisesSamAccountName, setOnPremisesSamAccountName] = useState('');
  const [userProfile, setUserProfile] = useState<UserProfile>(userProfileData);
  const [showCompanionInfo, setShowCompanionInfo] = useState(false);
  const [userDietaryPref, setUserDietaryPref] = useState<string[]>([]);
  const [companionDietaryPref, setCompanionDietaryPref] = useState<string[]>([]);
  const { formValues, handleChange, handleNext, handleBack, variant, margin } = useContext(AppContext);
  const { userId, userName, email, telephone, agp_UserRole, agp_UserTitle, agp_UserWarehouseNo, agp_UserDietaryPref, agp_CompanionUserName, agp_CompanionEmail, agp_CompanionTelephone, agp_CompanionInvitedBy, agp_CompanionRelationship, agp_CompanionDietaryPref } = formValues;

  useEffect(() => {

    //console.log(userProfile);

    // const profile = localStorage.getItem("UserProfile");
    // let userProfile: UserProfile = {} as UserProfile;
    // userProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

    //  let onPremisesSamAccountName = userProfile.onPremisesSamAccountName;
    //  userId.value = onPremisesSamAccountName;
    //  setOnPremisesSamAccountName(onPremisesSamAccountName);



    //console.log( userId.value);
    if (agp_UserRole.value == "Student") {
      setShowCompanionInfo(true);
      // agp_CompanionEmail.required = true;
      // agp_CompanionTelephone.required = true;
      // agp_CompanionDietaryPref.required = true;
    }
    else {
      setShowCompanionInfo(false);
      // agp_CompanionEmail.required = false;
      // agp_CompanionTelephone.required = false;
      // agp_CompanionDietaryPref.required = false;
    }


    // userName.value = userProfile.displayName;
    // email.value = userProfile.mail;
    // telephone.value = userProfile.mobilePhone;
    // email.value = account?.username ? account?.username : '';

    if (userProfile !== null && userProfile !== undefined) {
      //localStorage.setItem("UserProfile", JSON.stringify(profile));
      let onPremisesSamAccountName = userProfile.onPremisesSamAccountName;
      //console.log(profile.onPremisesSamAccountName);
      userId.value = onPremisesSamAccountName;
      //fetchRegistration('12345');
      fetchRegistration(onPremisesSamAccountName);
    }





  }, []);

  const fetchRegistration = async (userId: string) => {

    baseApi.Invitations.GetAnnualGraduationPartyRegisteration(userId).then(
      (response: any) => {
        if (response) {
          if (response !== null) {
            //console.log(response);

            if (response.title !== null && response.title !== undefined && response.title !== "") {
              agp_UserTitle.value = response.title;
            }
            if (response.userWarehouseNo !== null && response.userWarehouseNo !== undefined) {
              agp_UserWarehouseNo.value = response.userWarehouseNo;
            }
            if (response.userDietaryPref !== null && response.userDietaryPref !== undefined) {

              agp_UserDietaryPref.value = response.userDietaryPref;

              if (response.agp_UserDietaryPref === "") {
                let userDietaryprefArray: string[] = [];
                setUserDietaryPref(userDietaryprefArray);
              }
              else {
                let userDietaryprefArray = response.userDietaryPref.split(',');
                setUserDietaryPref(userDietaryprefArray);
              }

            }
            if (response.companionUserName !== null && response.companionUserName !== undefined) {
              agp_CompanionUserName.value = response.companionUserName;
            }
            if (response.companionEmail !== null && response.companionEmail !== undefined) {
              agp_CompanionEmail.value = response.companionEmail;
            }
            if (response.companionTelephone !== null && response.companionTelephone !== undefined) {
              agp_CompanionTelephone.value = response.companionTelephone;
            }
            if (response.companionInvitedBy !== null && response.companionInvitedBy !== undefined) {
              agp_CompanionInvitedBy.value = response.companionInvitedBy;
            }
            if (response.companionRelationship !== null && response.companionRelationship !== undefined) {
              agp_CompanionRelationship.value = response.companionRelationship;
            }
            if (response.companionDietaryPref !== null && response.companionDietaryPref !== undefined) {
              agp_CompanionDietaryPref.value = response.companionDietaryPref;

              if (response.companionDietaryPref === "") {
                let companionDietaryprefArray: string[] = [];
                setCompanionDietaryPref(companionDietaryprefArray);
              }
              else {
                let companionDietaryprefArray = response.companionDietaryPref.split(',');
                setCompanionDietaryPref(companionDietaryprefArray);
              }
            }
            // if (response.legalStatmentAcknowledgment !== null && response.legalStatmentAcknowledgment !== undefined) {
            //   agp_legalAcknowledgment.value = response.legalStatmentAcknowledgment;
            // }


          }

        }
      });
  }



  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({ email, telephone }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, email, telephone]
  );

  const handelNextHandler = () => {

    if (email === null || email === undefined || email.value === "") {
      alert.error('Email is required.!');
      isError();

    }
    else if (telephone === null || telephone === undefined || telephone.value === "") {
      alert.error('Telefonnummer is required.!');
      isError();

    }
    else if (agp_UserDietaryPref === null || agp_UserDietaryPref === undefined) {
      alert.error('Diæt præferencer is required.!');
    }
    else if (userId.value == userId === null || userId === undefined || userId.value === "") {
      alert.error('Lønnummer mangler. Tryk F5 for at opdatere.!');
    }
    else {
      handleNext();
    }

  }

  const userDietaryPrefChangeHandler = (event: any) => {
    const value = event.target.value;
    //console.log(value);
    // if (value[value.length - 1] === "all") {
    //   setSelected(selected.length === options.length ? [] : options);
    //   return;
    // } 

    if (value.find((x: string) => x === "Ingen præferencer") !== null && value.find((x: string) => x === "Ingen præferencer") !== undefined) {
      let newArray: string[] = ["Ingen præferencer"];
      setUserDietaryPref(newArray);
      agp_UserDietaryPref.value = "Ingen præferencer";
    }
    else {
      setUserDietaryPref(value);
      agp_UserDietaryPref.value = value.toString();
    }

  };
  const companionDietaryPrefChangeHandler = (event: any) => {
    const value = event.target.value;
    if (value.find((x: string) => x === "Ingen præferencer") !== null && value.find((x: string) => x === "Ingen præferencer") !== undefined) {
      let newArray: string[] = ["Ingen præferencer"];
      setCompanionDietaryPref(newArray);
      agp_CompanionDietaryPref.value = "Ingen præferencer";
    }
    else {
      setCompanionDietaryPref(value);
      agp_CompanionDietaryPref.value = value.toString();
    }

  };


  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.20rem", fontWeight: "bold" }}>
            Dine oplysninger
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Udfyld navn"
            name="userName"
            placeholder="Udfyld navn"
            value={account?.name}
            onChange={handleChange}
            error={!!userName.error}
            helperText={userName.error}
            required={userName.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Stillingsbetegnelse"
            name="agp_UserTitle"
            placeholder="Title"
            value={agp_UserTitle.value}
            onChange={handleChange}
            error={!!agp_UserTitle.error}
            helperText={agp_UserTitle.error}
            required={agp_UserTitle.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Lønnummer"
            name="userId"
            placeholder="Enter User ID"
            value={userId.value}
            onChange={handleChange}
            error={!!userId.error}
            helperText={userId.error}
            required={userId.required}
            inputProps={{ readOnly: true }}
          />
          {/* <p style={{ fontWeight: 'bold' }}>Lønnummer mangler. Tryk F5 for at opdatere</p> */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Udfyld mail"
            name="email"
            placeholder="Udfyld mail"
            value={email.value}
            onChange={handleChange}
            error={!!email.error}
            helperText={email.error}
            required={email.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Telefonnummer"
            name="telephone"
            placeholder="enter telefonnummer"
            value={telephone.value}
            onChange={handleChange}
            error={!!telephone.error}
            helperText={telephone.error}
            required={telephone.required}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Varehusnr./Butiksnr"
            name="agp_UserWarehouseNo"
            placeholder="Warehouse no./Store no."
            value={agp_UserWarehouseNo.value}
            onChange={handleChange}
            error={!!agp_UserWarehouseNo.error}
            helperText={agp_UserWarehouseNo.error}
            required={agp_UserWarehouseNo.required}

          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Afdeling"
            name="department"
            value={department.value}
            onChange={handleChange}
            error={!!department.error}
            helperText={department.error}
            required={department.required}
          >
            <option value=""></option>
            <option value="Grocery">{"Grocery"}</option>
            <option value="Finance/BD">{"Finance/BD"}</option>
            <option value="ITS">{"ITS"}</option>
            <option value="Technical Affairs">{"Technical Affairs"}</option>
            <option value="Nonfood">{"Nonfood"}</option>
            <option value="Fresh">{"Fresh"}</option>
            <option value="E-commerce & Digital">
              {"E-commerce & Digital"}
            </option>
            <option value="HR/Bøgehøj">{"HR/Bøgehøj"}</option>
            <option value="føtex">{"føtex"}</option>
            <option value="Legal & Property">{"Legal & Property"}</option>
            <option value="Logistik">{"Logistik"}</option>
            <option value="Textile">{"Textile"}</option>
            <option value="Bilka/BR">{"Bilka/BR"}</option>
            <option value="Salling Group">{"Salling Group"}</option>
            <option value="Commercial support functions">
              {"Commercial support functions"}
            </option>
            <option value="Intern Service">{"Intern Service"}</option>
            <option value="Corporate Communication">
              {"Corporate Communication"}
            </option>
            <option value="Kundeservice Tilst">{"Kundeservice Tilst"}</option>

          </TextField>
        </Grid> */}
        <Grid item xs={6}>
          {/* <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Diæt præferencer"
            name="agp_UserDietaryPref"
            value={agp_UserDietaryPref.value}
            onChange={handleChange}
            error={!!agp_UserDietaryPref.error}
            helperText={agp_UserDietaryPref.error}
            required={agp_UserDietaryPref.required}
          >
            <option value=""></option>
            <option value="Ingen præferencer">{"Ingen præferencer"}</option>
            <option value="Vegetarisk">{"Vegetarisk"}</option>
            <option value="Vegansk">{"Vegansk"}</option>
            <option value="Laktosefri">{"Laktosefri"}</option>
            <option value="Glutenfri">{"Glutenfri"}</option>
            <option value="Nøddefri">{"Nøddefri"}</option>
            <option value="Spiser ikke svinekød">{"Spiser ikke svinekød"}</option>
          </TextField> */}
          <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-select-label">Diæt præferencer</InputLabel>
            <Select
              variant={variant}
              labelId="mutiple-select-label"
              multiple
              value={userDietaryPref}
              onChange={userDietaryPrefChangeHandler}
              renderValue={(selected) => (selected as string[]).join(', ')}
              MenuProps={MenuProps}
            >

              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <Checkbox checked={userDietaryPref.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {showCompanionInfo == true ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: "1.20rem", fontWeight: "bold", marginTop: "20px" }}>
              Oplysninger på ledsager
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              variant={variant}
              margin={margin}
              fullWidth
              label="Udfyld navn"
              name="agp_CompanionUserName"
              placeholder="Udfyld navn"
              value={agp_CompanionUserName.value}
              onChange={handleChange}
              error={!!agp_CompanionUserName.error}
              helperText={userName.error}
              required={userName.required}
              inputProps={{ readOnly: false }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              variant={variant}
              margin={margin}
              fullWidth
              label="Udfyld mail"
              name="agp_CompanionEmail"
              placeholder="Udfyld mail"
              value={agp_CompanionEmail.value}
              onChange={handleChange}
              error={!!agp_CompanionEmail.error}
              helperText={agp_CompanionEmail.error}
              required={agp_CompanionEmail.required}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              variant={variant}
              margin={margin}
              fullWidth
              label="Telefonnummer"
              name="agp_CompanionTelephone"
              placeholder="enter telefonnummer"
              value={agp_CompanionTelephone.value}
              onChange={handleChange}
              error={!!agp_CompanionEmail.error}
              helperText={agp_CompanionEmail.error}
              required={agp_CompanionEmail.required}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              type="text"
              variant={variant}
              margin={margin}
              fullWidth
              label="Inviteret af"
              name="agp_CompanionInvitedBy"
              placeholder="Student's name and shop no."
              value={agp_CompanionInvitedBy.value}
              onChange={handleChange}
              error={!!agp_CompanionInvitedBy.error}
              helperText={agp_CompanionInvitedBy.error}
              required={agp_CompanionInvitedBy.required}
              inputProps={{ readOnly: false }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              type="text"
              variant={variant}
              margin={margin}
              fullWidth
              label="Udfyld relation til ledsager"
              name="agp_CompanionRelationship"
              placeholder="Udfyld relation til ledsager"
              value={agp_CompanionRelationship.value}
              onChange={handleChange}
              error={!!userId.error}
              helperText={userId.error}
              required={userId.required}
              inputProps={{ readOnly: false }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <TextField
              variant={variant}
              margin={margin}
              fullWidth
              select
              SelectProps={{
                native: true,
              }}
              label="Dietary preferences"
              name="agp_CompanionDietaryPref"
              value={agp_CompanionDietaryPref.value}
              onChange={handleChange}
              error={!!agp_CompanionDietaryPref.error}
              helperText={agp_CompanionDietaryPref.error}
              required={agp_CompanionDietaryPref.required}
            >
              <option value=""></option>
              <option value="Ingen præferencer">{"Ingen præferencer"}</option>
              <option value="Vegetarisk">{"Vegetarisk"}</option>
              <option value="Vegansk">{"Vegansk"}</option>
              <option value="Laktosefri">{"Laktosefri"}</option>
              <option value="Glutenfri">{"Glutenfri"}</option>
              <option value="Nøddefri">{"Nøddefri"}</option>
              <option value="Spiser ikke svinekød">{"Spiser ikke svinekød"}</option>
            </TextField> */}
            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-select-label">Diæt præferencer</InputLabel>
              <Select
                variant={variant}
                labelId="mutiple-select-label"
                multiple
                value={companionDietaryPref}
                onChange={companionDietaryPrefChangeHandler}
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={MenuProps}
              >
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={companionDietaryPref.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : null}
      <div
        style={{
          display: "flex",
          marginTop: 50,
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          TILBAGE
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handelNextHandler}
        >
          Næste
        </Button>
      </div>

    </>

  );


}

