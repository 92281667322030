import React, { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { AppContext } from '../Context'
import { useAccount, useMsal } from '@azure/msal-react'
import { UserProfile } from '../../_models/common/profile.model'
import baseApi from '../../_api/base.api';
import { FamilyDayRegistrationModel } from "../../_models/invitation/FamilyDayRegistratoin.model";
import { useAlert } from "react-alert";
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Loader from "../common/loader/loader";
// Applied to all fields
const variant = "outlined";
const margin = "normal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    box: {
      padding: theme.spacing(3)
    },
    title: {
      marginTop: 30
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    }
  }),
);
interface Props {
  familyDayRegistration: FamilyDayRegistrationModel;
}
export interface FamilyDayEventsParticipants {
  eventName: string;
  totalCount: number;
}
export interface FamilyDayEventsRegisterationModel {
  userId?: string;
  familyDayRegistrationId?: number;
  eventCategory?: string;
  eventType?: string;
  eventName?: string;
  eventDisplayName?: string;
  timeSlot?: string;
  adults?: number;
  childrens?: number;
}

export default function FamilyDayPresentationsForm({ familyDayRegistration }: Props) {
  const classes = useStyles();
  const alert = useAlert();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [onPremisesSamAccountName, setOnPremisesSamAccountName] = useState('');
  const [userRegistration, setuserRegistration] = useState<FamilyDayRegistrationModel>(familyDayRegistration);
  const [loadPresentationForm, setLoadPresentationForm] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [totalSelectedParticipants, setTotalSelectedParticipants] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [perBankDisabled, setPerBankDisabled] = useState(false);
  const [foredrag1Disabled, setForedrag1Disabled] = useState(false);
  const [foredrag2Disabled, setForedrag2Disabled] = useState(false);
  const [foredrag3Disabled, setForedrag3Disabled] = useState(false);
  const [foredrag4Disabled, setForedrag4Disabled] = useState(false);
  const [foredrag5Disabled, setForedrag5Disabled] = useState(false);
  const [foredrag6Disabled, setForedrag6Disabled] = useState(false);
  const [foredrag7Disabled, setForedrag7Disabled] = useState(false);
  const [foredrag8Disabled, setForedrag8Disabled] = useState(false);
  const [foredrag9Disabled, setForedrag9Disabled] = useState(false);
  const [foredrag10Disabled, setForedrag10Disabled] = useState(false);
  const [foredrag11Disabled, setForedrag11Disabled] = useState(false);
  const [foredrag12Disabled, setForedrag12Disabled] = useState(false);
  const [foredrag13Disabled, setForedrag13Disabled] = useState(false);
  const [foredrag14Disabled, setForedrag14Disabled] = useState(false);
  const [foredrag15Disabled, setForedrag15Disabled] = useState(false);
  const [lagerrundtur1Disabled, setLagerrundtur1Disabled] = useState(false);
  const [lagerrundtur2Disabled, setLagerrundtur2Disabled] = useState(false);
  const [lagerrundtur3Disabled, setLagerrundtur3Disabled] = useState(false);
  const [lagerrundtur4Disabled, setLagerrundtur4Disabled] = useState(false);
  const [lagerrundtur5Disabled, setLagerrundtur5Disabled] = useState(false);
  const [lagerrundtur6Disabled, setLagerrundtur6Disabled] = useState(false);
  const { formValues, handleChange, handleBack, handleNext, variant, margin } = useContext(AppContext);
  const {
    userId,
    userName,
    email,
    familDayRegisterationId,
    familyDayDepartment,
    familyDayAdults,
    familyDayChildsUnder12,
    familyDayPerBankAdults,
    //familyDayPerBankChildrens,
    familyDayForedrag1Adults,
    //familyDayForedrag1Childrens,
    familyDayForedrag2Adults,
    //familyDayForedrag2Childrens,
    familyDayForedrag3Adults,
    //familyDayForedrag3Childrens,
    familyDayForedrag4Adults,
    //familyDayForedrag4Childrens,
    familyDayForedrag5Adults,
    ///familyDayForedrag5Childrens,
    familyDayForedrag6Adults,
    //familyDayForedrag6Childrens,
    familyDayForedrag7Adults,
    //familyDayForedrag7Childrens,
    familyDayForedrag8Adults,
    //familyDayForedrag8Childrens,
    familyDayForedrag9Adults,
    //familyDayForedrag9Childrens,
    familyDayForedrag10Adults,
    //familyDayForedrag10Childrens,
    familyDayForedrag11Adults,
    //familyDayForedrag11Childrens,
    familyDayForedrag12Adults,
    //familyDayForedrag12Childrens,
    familyDayForedrag13Adults,
    //familyDayForedrag13Childrens,
    familyDayForedrag14Adults,
    //familyDayForedrag14Childrens,
    familyDayForedrag15Adults,
    //familyDayForedrag15Childrens,
    familyDayLagerrundtur1Adults,
    //familyDayLagerrundtur1Childrens,
    familyDayLagerrundtur2Adults,
    //familyDayLagerrundtur2Childrens,
    familyDayLagerrundtur3Adults,
    //familyDayLagerrundtur3Childrens,
    familyDayLagerrundtur4Adults,
    //familyDayLagerrundtur4Childrens,
    familyDayLagerrundtur5Adults,
    //familyDayLagerrundtur5Childrens,
    familyDayLagerrundtur6Adults,
    //familyDayLagerrundtur6Childrens

  } = formValues;


  let options = Array.from(Array(familyDayAdults.value + 1).keys()).map(item => {
    return (<option key={item} value={item}>{item}</option>);
  });


  useEffect(() => {

    const profile = localStorage.getItem("UserProfile");
    let userProfile: UserProfile = {} as UserProfile;
    userProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

    setOnPremisesSamAccountName(userProfile.onPremisesSamAccountName);
    userId.value = userProfile.onPremisesSamAccountName;
    userName.value = userProfile.displayName;
    email.value = userProfile.mail;


    fetchRegistration(userProfile.onPremisesSamAccountName);
    fetchFamilyDayEventsParticipants();

  }, []);

  function fetchRegistration(userId: string) {

    baseApi.Invitations.GetFamilyDayRegistration(userId).then(
      (response: FamilyDayRegistrationModel) => {
        if (response) {
          familDayRegisterationId.value = response.id;
          familyDayDepartment.value = response.afdeling;
          familyDayAdults.value = response.numberOfAdults;
          familyDayChildsUnder12.value = response.numberOfChildsUnder12;
          setLoadPresentationForm(true);
        }
      });
  }

  function fetchFamilyDayEventsParticipants() {
    baseApi.Invitations.GetFamilyDayEventsParticipants().then(
      (response: FamilyDayEventsParticipants[]) => {
        if (response) {

          if (response != null && response != undefined) {

            let perBankEvent = response.find(x => x.eventName == "Per Bank");

            if (perBankEvent != null && perBankEvent != undefined) {
              // console.log(perBankEvent.totalCount)
              if (perBankEvent.totalCount >= 600) {
                familyDayPerBankAdults.value = "0";
                setPerBankDisabled(true);
              }
            }
            let foredrag1Event = response.find(x => x.eventName == "Foredrag 1");
            if (foredrag1Event != null && foredrag1Event != undefined) {
              if (foredrag1Event.totalCount >= 263) {
                familyDayForedrag1Adults.value = "0";
                setForedrag1Disabled(true);
              }
            }
            let foredrag2Event = response.find(x => x.eventName == "Foredrag 2");
            if (foredrag2Event != null && foredrag2Event != undefined) {
              if (foredrag2Event.totalCount >= 80) {
                familyDayForedrag2Adults.value = "0";
                setForedrag2Disabled(true);
              }
            }
            let foredrag3Event = response.find(x => x.eventName == "Foredrag 3");
            if (foredrag3Event != null && foredrag3Event != undefined) {
              if (foredrag3Event.totalCount >= 95) {
                familyDayForedrag3Adults.value = "0";
                setForedrag3Disabled(true);
              }
            }
            let foredrag4Event = response.find(x => x.eventName == "Foredrag 4");
            if (foredrag4Event != null && foredrag4Event != undefined) {
              if (foredrag4Event.totalCount >= 263) {
                familyDayForedrag4Adults.value = "0";
                setForedrag4Disabled(true);
              }
            }
            let foredrag5Event = response.find(x => x.eventName == "Foredrag 5");
            if (foredrag5Event != null && foredrag5Event != undefined) {
              if (foredrag5Event.totalCount >= 80) {
                familyDayForedrag5Adults.value = "0";
                setForedrag5Disabled(true);
              }
            }
            let foredrag6Event = response.find(x => x.eventName == "Foredrag 6");
            if (foredrag6Event != null && foredrag6Event != undefined) {
              if (foredrag6Event.totalCount >= 95) {
                familyDayForedrag6Adults.value = "0";
                setForedrag6Disabled(true);
              }
            }
            let foredrag7Event = response.find(x => x.eventName == "Foredrag 7");
            if (foredrag7Event != null && foredrag7Event != undefined) {
              if (foredrag7Event.totalCount >= 263) {
                familyDayForedrag7Adults.value = "0";
                setForedrag7Disabled(true);
              }
            }
            let foredrag8Event = response.find(x => x.eventName == "Foredrag 8");
            if (foredrag8Event != null && foredrag8Event != undefined) {
              if (foredrag8Event.totalCount >= 80) {
                familyDayForedrag8Adults.value = "0";
                setForedrag8Disabled(true);
              }
            }
            let foredrag9Event = response.find(x => x.eventName == "Foredrag 9");
            if (foredrag9Event != null && foredrag9Event != undefined) {
              if (foredrag9Event.totalCount >= 95) {
                familyDayForedrag9Adults.value = "0";
                setForedrag9Disabled(true);
              }
            }
            let foredrag10Event = response.find(x => x.eventName == "Foredrag 10");
            if (foredrag10Event != null && foredrag10Event != undefined) {
              if (foredrag10Event.totalCount >= 263) {
                familyDayForedrag10Adults.value = "0";
                setForedrag10Disabled(true);
              }
            }
            let foredrag11Event = response.find(x => x.eventName == "Foredrag 11");
            if (foredrag11Event != null && foredrag11Event != undefined) {
              if (foredrag11Event.totalCount >= 80) {
                familyDayForedrag11Adults.value = "0";
                setForedrag11Disabled(true);
              }
            }
            let foredrag12Event = response.find(x => x.eventName == "Foredrag 12");
            if (foredrag12Event != null && foredrag12Event != undefined) {
              if (foredrag12Event.totalCount >= 95) {
                familyDayForedrag12Adults.value = "0";
                setForedrag12Disabled(true);
              }
            }
            let foredrag13Event = response.find(x => x.eventName == "Foredrag 13");
            if (foredrag13Event != null && foredrag13Event != undefined) {
              if (foredrag13Event.totalCount >= 600) {
                familyDayForedrag13Adults.value = "0";
                setForedrag13Disabled(true);
              }
            }
            // let foredrag14Event = response.find(x => x.eventName == "Foredrag 14");
            // if (foredrag14Event != null && foredrag14Event != undefined) {
            //   if (foredrag14Event.totalCount >= 95) {
            //     familyDayForedrag14Adults.value = "Optaget";
            //     setForedrag14Disabled(true);
            //   }
            // }
            // let foredrag15Event = response.find(x => x.eventName == "Foredrag 15");
            // if (foredrag15Event != null && foredrag15Event != undefined) {
            //   if (foredrag15Event.totalCount >= 55) {
            //     familyDayForedrag15Adults.value = "Optaget";
            //     setForedrag15Disabled(true);
            //   }
            // }
            let lagerrundtur1 = response.find(x => x.eventName == "Lagerrundtur 1");
            if (lagerrundtur1 != null && lagerrundtur1 != undefined) {
              if (lagerrundtur1.totalCount >= 25) {
                familyDayLagerrundtur1Adults.value = "0";
                setLagerrundtur1Disabled(true);
              }
            }
            let lagerrundtur2 = response.find(x => x.eventName == "Lagerrundtur 2");
            if (lagerrundtur2 != null && lagerrundtur2 != undefined) {
              if (lagerrundtur2.totalCount >= 25) {
                familyDayLagerrundtur2Adults.value = "0";
                setLagerrundtur2Disabled(true);
              }
            }
            let lagerrundtur3 = response.find(x => x.eventName == "Lagerrundtur 3");
            if (lagerrundtur3 != null && lagerrundtur3 != undefined) {
              if (lagerrundtur3.totalCount >= 25) {
                familyDayLagerrundtur3Adults.value = "0";
                setLagerrundtur3Disabled(true);
              }
            }
            let lagerrundtur4 = response.find(x => x.eventName == "Lagerrundtur 4");
            if (lagerrundtur4 != null && lagerrundtur4 != undefined) {
              if (lagerrundtur4.totalCount >= 25) {
                familyDayLagerrundtur4Adults.value = "0";
                setLagerrundtur4Disabled(true);
              }
            }
            let lagerrundtur5 = response.find(x => x.eventName == "Lagerrundtur 5");
            if (lagerrundtur5 != null && lagerrundtur5 != undefined) {
              if (lagerrundtur5.totalCount >= 25) {
                familyDayLagerrundtur5Adults.value = "0";
                setLagerrundtur5Disabled(true);
              }
            }
            let lagerrundtur6 = response.find(x => x.eventName == "Lagerrundtur 6");
            if (lagerrundtur6 != null && lagerrundtur6 != undefined) {
              if (lagerrundtur6.totalCount >= 25) {
                familyDayLagerrundtur6Adults.value = "0";
                setLagerrundtur6Disabled(true);
              }
            }



          }
          //console.log(response)
        }
      });
  }

  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({
        userId,
        userName,
        email,
        familyDayDepartment,
        familyDayAdults,
        familyDayChildsUnder12,
        familyDayPerBankAdults,
        familyDayForedrag1Adults,
        familyDayForedrag2Adults,
        familyDayForedrag3Adults,
        familyDayForedrag4Adults,
        familyDayForedrag5Adults,
        familyDayForedrag6Adults,
        familyDayForedrag7Adults,
        familyDayForedrag8Adults,
        familyDayForedrag9Adults,
        familyDayForedrag10Adults,
        familyDayForedrag11Adults,
        familyDayForedrag12Adults,
        familyDayForedrag13Adults,
        familyDayForedrag14Adults,
        familyDayForedrag15Adults
      }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, userId, userName, email, familyDayDepartment, familyDayAdults, familyDayChildsUnder12, familyDayPerBankAdults,
      familyDayForedrag1Adults,
      familyDayForedrag2Adults,
      familyDayForedrag3Adults,
      familyDayForedrag4Adults,
      familyDayForedrag5Adults,
      familyDayForedrag6Adults,
      familyDayForedrag7Adults,
      familyDayForedrag8Adults,
      familyDayForedrag9Adults,
      familyDayForedrag10Adults,
      familyDayForedrag11Adults,
      familyDayForedrag12Adults,
      familyDayForedrag13Adults,
      familyDayForedrag14Adults,
      familyDayForedrag15Adults]
  );

  const handleChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {

    //let familyDaySingupParticipatns = Number(familyDayChildsUnder12.value) + Number(familyDayAdults.value);
    let familyDaySingupParticipatns = Number(familyDayAdults.value);
    let maxEventsAllowed = familyDaySingupParticipatns * 2;
    let newTotal = 0
    //#region Kl. 9.30 – 10.30 SLOT
    if (event.target.name == "familyDayPerBankAdults") {

      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayLagerrundtur1Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        //console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayLagerrundtur1Adults") {

      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayPerBankAdults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);


        //console.log(totalEventsParticipatns);
        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    //#endregion
    //#region Kl. 10.15 – 10.45 and Kl. 10.15 – 11.15 SLOT
    if (event.target.name == "familyDayForedrag1Adults") {

      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag2Adults.value) + Number(familyDayForedrag3Adults.value) + Number(familyDayLagerrundtur2Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsru');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag2Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag1Adults.value) + Number(familyDayForedrag3Adults.value) + Number(familyDayLagerrundtur2Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);


        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsru');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag3Adults") {

      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag1Adults.value) + Number(familyDayForedrag2Adults.value) + Number(familyDayLagerrundtur2Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayLagerrundtur2Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag1Adults.value) + Number(familyDayForedrag2Adults.value) + Number(familyDayForedrag3Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    //#endregion
    //#region Kl. 11.00 – 11.30 and Kl. Kl. 11.00 – 12.00 SLOT
    if (event.target.name == "familyDayForedrag4Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag5Adults.value) + Number(familyDayForedrag6Adults.value) + Number(familyDayLagerrundtur2Adults.value) + Number(familyDayLagerrundtur3Adults.value);
        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag5Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {
        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag4Adults.value) + Number(familyDayForedrag6Adults.value) + Number(familyDayLagerrundtur2Adults.value) + Number(familyDayLagerrundtur3Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag6Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag4Adults.value) + Number(familyDayForedrag5Adults.value) + Number(familyDayLagerrundtur2Adults.value) + Number(familyDayLagerrundtur3Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayLagerrundtur3Adults") {

      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag4Adults.value) + Number(familyDayForedrag5Adults.value) + Number(familyDayLagerrundtur2Adults.value) + Number(familyDayLagerrundtur6Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    //#endregion
    //#region Kl. 11.45 - 12.15 and Kl. 11.45 - 12.45
    if (event.target.name == "familyDayForedrag7Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag8Adults.value) + Number(familyDayForedrag9Adults.value) + Number(familyDayLagerrundtur3Adults.value) + Number(familyDayLagerrundtur4Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag8Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag7Adults.value) + Number(familyDayForedrag9Adults.value) + Number(familyDayLagerrundtur3Adults.value) + Number(familyDayLagerrundtur4Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag9Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag7Adults.value) + Number(familyDayForedrag8Adults.value) + Number(familyDayLagerrundtur3Adults.value) + Number(familyDayLagerrundtur4Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayLagerrundtur4Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {
        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag7Adults.value) + Number(familyDayForedrag8Adults.value) + Number(familyDayLagerrundtur3Adults.value) + Number(familyDayForedrag9Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        console.log(newTotal);

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }

    //#endregion
    //#region Kl. 12.30. - 13.00 and Kl. 12.30 - 13.30
    if (event.target.name == "familyDayForedrag10Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag11Adults.value) + Number(familyDayForedrag12Adults.value) + Number(familyDayLagerrundtur4Adults.value) + Number(familyDayLagerrundtur5Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag11Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag10Adults.value) + Number(familyDayForedrag12Adults.value) + Number(familyDayLagerrundtur4Adults.value) + Number(familyDayLagerrundtur5Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayForedrag12Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag10Adults.value) + Number(familyDayForedrag11Adults.value) + Number(familyDayLagerrundtur4Adults.value) + Number(familyDayLagerrundtur5Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    if (event.target.name == "familyDayLagerrundtur5Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag10Adults.value) + Number(familyDayForedrag11Adults.value) + Number(familyDayLagerrundtur4Adults.value) + Number(familyDayForedrag12Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    //#endregion
    //#region Kl. 13.15 - 13.45 and Kl. 13.15 - 14.15
    if (event.target.name == "familyDayForedrag13Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {
        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag14Adults.value) + Number(familyDayForedrag15Adults.value) + Number(familyDayLagerrundtur5Adults.value) + Number(familyDayLagerrundtur6Adults.value);


        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)
          + Number(familyDayLagerrundtur6Adults.value)

        if (Number(event.target.value) > Number(familyDayAdults.value)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    // if (event.target.name == "familyDayForedrag14Adults") {
    //   if (event.target.value !== undefined && event.target.value !== null) {

    //     let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag13Adults.value) + Number(familyDayForedrag15Adults.value) + Number(familyDayLagerrundtur5Adults.value) + Number(familyDayLagerrundtur6Adults.value);
    //     if (Number(event.target.value) > Number(familyDayAdults.value)) {
    //       alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
    //     }
    //     else if (totalEventsParticipatns > familyDaySingupParticipatns) {
    //       alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
    //     }
    //     else {
    //       handelSelectedEventsList(event.target.value, 'familyDayForedrag14Adults');
    //       if (totalSelectedParticipants  > maxEventsAllowed) {
    //         alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
    //       }
    //       else {
    //         handleChange(event);
    //       }

    //     }
    //   }
    // }
    // if (event.target.name == "familyDayForedrag15Adults") {
    //   if (event.target.value !== undefined && event.target.value !== null) {



    //     let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag13Adults.value) + Number(familyDayForedrag14Adults.value) + Number(familyDayLagerrundtur5Adults.value) + Number(familyDayLagerrundtur6Adults.value);
    //     if (Number(event.target.value) > Number(familyDayAdults.value)) {
    //       alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
    //     }
    //     else if (totalEventsParticipatns > familyDaySingupParticipatns) {
    //       alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
    //     }
    //     else  {
    //       handelSelectedEventsList(event.target.value, 'familyDayForedrag15Adults');
    //       if (totalSelectedParticipants  > maxEventsAllowed) {
    //         alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
    //       }
    //       else
    //       {
    //         handleChange(event);
    //       }

    //     }
    //   }
    // }
    if (event.target.name == "familyDayLagerrundtur6Adults") {
      if (event.target.value !== undefined && event.target.value !== null) {

        let totalEventsParticipatns = Number(event.target.value) + Number(familyDayForedrag13Adults.value) + Number(familyDayLagerrundtur5Adults.value);

        newTotal = Number(event.target.value)
          + Number(familyDayPerBankAdults.value)
          + Number(familyDayForedrag1Adults.value)
          + Number(familyDayForedrag2Adults.value)
          + Number(familyDayForedrag3Adults.value)
          + Number(familyDayForedrag4Adults.value)
          + Number(familyDayForedrag5Adults.value)
          + Number(familyDayForedrag6Adults.value)
          + Number(familyDayForedrag7Adults.value)
          + Number(familyDayForedrag8Adults.value)
          + Number(familyDayForedrag9Adults.value)
          + Number(familyDayForedrag10Adults.value)
          + Number(familyDayForedrag11Adults.value)
          + Number(familyDayForedrag12Adults.value)
          + Number(familyDayForedrag13Adults.value)
          + Number(familyDayLagerrundtur1Adults.value)
          + Number(familyDayLagerrundtur2Adults.value)
          + Number(familyDayLagerrundtur3Adults.value)
          + Number(familyDayLagerrundtur4Adults.value)
          + Number(familyDayLagerrundtur5Adults.value)


        if (Number(totalEventsParticipatns) > Number(familyDaySingupParticipatns)) {
          alert.show('Du forsøger at tilmelde flere voksne til et foredrag / en rundturen end du har tilmeldt til dagen');
        }
        else if (totalEventsParticipatns > familyDaySingupParticipatns) {
          alert.show('Du er allerede tilmeldt et foredrag / en rundtur i dette tidsrum');
        }
        else if (newTotal > maxEventsAllowed) {
          alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
        }
        else {
          // handelSelectedEventsList(event.target.value, 'familyDayForedrag1Adults');
          // if (totalSelectedParticipants > maxEventsAllowed) {
          //   alert.error('Det er desværre kun muligt at booke 2 events per voksen deltager');
          // }
          // else {
          //   handleChange(event);
          // }

          handleChange(event);

        }
      }
    }
    //#region

  };
  const nextTabHandler = () => {

    //handleNext();
    handleSubmit();

  }
  const backButtonHandler = () => {
    familyDayPerBankAdults.value = "";
    familyDayForedrag1Adults.value = "";
    familyDayForedrag2Adults.value = "";
    familyDayForedrag3Adults.value = "";
    familyDayForedrag4Adults.value = "";
    familyDayForedrag5Adults.value = "";
    familyDayForedrag6Adults.value = "";
    familyDayForedrag7Adults.value = "";
    familyDayForedrag8Adults.value = "";
    familyDayForedrag9Adults.value = "";
    familyDayForedrag10Adults.value = "";
    familyDayForedrag11Adults.value = "";
    familyDayForedrag12Adults.value = "";
    familyDayForedrag13Adults.value = "";
    familyDayForedrag14Adults.value = "";
    familyDayForedrag15Adults.value = "";
    familyDayLagerrundtur1Adults.value = "";
    familyDayLagerrundtur2Adults.value = "";
    familyDayLagerrundtur3Adults.value = "";
    familyDayLagerrundtur4Adults.value = "";
    familyDayLagerrundtur5Adults.value = "";
    familyDayLagerrundtur6Adults.value = "";

    handleBack();
  }

  const handleSubmit = () => {

    //console.log(familDayRegisterationId.value);
    let familyDayEventsRegisterationModel: FamilyDayEventsRegisterationModel[] = [];

    if ((familyDayPerBankAdults !== null && familyDayPerBankAdults !== undefined)) {
      let perBankEventModel: FamilyDayEventsRegisterationModel = {};
      perBankEventModel.userId = userId.value;
      perBankEventModel.familyDayRegistrationId = familDayRegisterationId.value;
      perBankEventModel.eventType = 'Presentation';
      perBankEventModel.eventName = 'Per Bank';
      perBankEventModel.eventDisplayName = 'Salling Group – Historien bag succesen';
      perBankEventModel.timeSlot = 'Kl. 9.15 – 9.45';
      if (familyDayPerBankAdults.value !== null 
        && familyDayPerBankAdults.value !== undefined 
        && (familyDayPerBankAdults.value !== "0" 
        && familyDayPerBankAdults.value !== "" 
        && familyDayPerBankAdults.value !== "Optaget")) {
        perBankEventModel.adults = familyDayPerBankAdults.value;
        familyDayEventsRegisterationModel.push(perBankEventModel);
      }
      // else {
      //   perBankEventModel.adults = 0;
      // }


    }
    if ((familyDayForedrag1Adults !== null && familyDayForedrag1Adults !== undefined)) {
      let foredrag1Model: FamilyDayEventsRegisterationModel = {};
      foredrag1Model.userId = userId.value;
      foredrag1Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag1Model.eventType = 'Presentation';
      foredrag1Model.eventName = 'Foredrag 1';
      foredrag1Model.eventDisplayName = 'Salling Fondene – vores bidrag til samfundet';
      foredrag1Model.timeSlot = 'Kl. 10.15 – 10.45';
      if (familyDayForedrag1Adults.value !== null 
        && familyDayForedrag1Adults.value !== undefined 
        && familyDayForedrag1Adults.value !== "0" 
        && familyDayForedrag1Adults.value !== "" 
        && familyDayForedrag1Adults.value !== "Optaget") {
        foredrag1Model.adults = familyDayForedrag1Adults.value;
        familyDayEventsRegisterationModel.push(foredrag1Model);
      }
      // else {
      //   foredrag1Model.adults = 0;
      // }


    }
    if ((familyDayForedrag2Adults !== null && familyDayForedrag2Adults !== undefined)) {
      let foredrag2Model: FamilyDayEventsRegisterationModel = {};
      foredrag2Model.userId = userId.value;
      foredrag2Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag2Model.eventType = 'Presentation';
      foredrag2Model.eventName = 'Foredrag 2';
      foredrag2Model.eventDisplayName = 'Kunden i fokus';
      foredrag2Model.timeSlot = 'Kl. 10.15 – 10.45';
      if (familyDayForedrag2Adults.value !== null 
        && familyDayForedrag2Adults.value !== undefined 
        && familyDayForedrag2Adults.value !== "0" 
        && familyDayForedrag2Adults.value !== "" 
        && familyDayForedrag2Adults.value !== "Optaget") {
        foredrag2Model.adults = familyDayForedrag2Adults.value;
        familyDayEventsRegisterationModel.push(foredrag2Model);
      }
      // else {
      //   foredrag2Model.adults = 0;
      // }


    }
    if ((familyDayForedrag3Adults !== null && familyDayForedrag3Adults !== undefined)) {
      let foredrag3Model: FamilyDayEventsRegisterationModel = {};
      foredrag3Model.userId = userId.value;
      foredrag3Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag3Model.eventType = 'Presentation';
      foredrag3Model.eventName = 'Foredrag 3';
      foredrag3Model.eventDisplayName = 'Danmarks største arbejdsgiver';
      foredrag3Model.timeSlot = 'Kl. 10.15 – 10.45';
      if (familyDayForedrag3Adults.value !== null 
        && familyDayForedrag3Adults.value !== undefined 
        && familyDayForedrag3Adults.value !== "0" 
        && familyDayForedrag3Adults.value !== "" 
        && familyDayForedrag3Adults.value !== "Optaget") {
        foredrag3Model.adults = familyDayForedrag3Adults.value;
        familyDayEventsRegisterationModel.push(foredrag3Model);
      }
      // else {
      //   foredrag3Model.adults = 0;
      // }


    }
    if ((familyDayForedrag4Adults !== null && familyDayForedrag4Adults !== undefined)) {
      let foredrag4Model: FamilyDayEventsRegisterationModel = {};
      foredrag4Model.userId = userId.value;
      foredrag4Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag4Model.eventType = 'Presentation';
      foredrag4Model.eventName = 'Foredrag 4';
      foredrag4Model.eventDisplayName = 'Netto – Salling Groups frække hund';
      foredrag4Model.timeSlot = 'Kl. 11.00 – 11.30';
      if (familyDayForedrag4Adults.value !== null 
        && familyDayForedrag4Adults.value !== undefined 
        && familyDayForedrag4Adults.value !== "0" 
        && familyDayForedrag4Adults.value !== "" 
        && familyDayForedrag4Adults.value !== "Optaget") {
        foredrag4Model.adults = familyDayForedrag4Adults.value;
        familyDayEventsRegisterationModel.push(foredrag4Model);
      }
      // else {
      //   foredrag4Model.adults = 0;
      // }


    }
    if ((familyDayForedrag5Adults !== null && familyDayForedrag5Adults !== undefined)) {
      let foredrag5Model: FamilyDayEventsRegisterationModel = {};
      foredrag5Model.userId = userId.value;
      foredrag5Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag5Model.eventType = 'Presentation';
      foredrag5Model.eventName = 'Foredrag 5';
      foredrag5Model.timeSlot = 'Kl. 11.00 – 11.30';
      foredrag5Model.eventDisplayName = 'føtex i et omskifteligt marked';
      if (familyDayForedrag5Adults.value !== null
        && familyDayForedrag5Adults.value !== undefined
        && familyDayForedrag5Adults.value !== "0"
        && familyDayForedrag5Adults.value !== ""
        && familyDayForedrag5Adults.value !== "Optaget") {
        foredrag5Model.adults = familyDayForedrag5Adults.value;
        familyDayEventsRegisterationModel.push(foredrag5Model);
      }
      // else {
      //   foredrag5Model.adults = 0;
      // }


    }
    if ((familyDayForedrag6Adults !== null && familyDayForedrag6Adults !== undefined)) {
      let foredrag6Model: FamilyDayEventsRegisterationModel = {};
      foredrag6Model.userId = userId.value;
      foredrag6Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag6Model.eventType = 'Presentation';
      foredrag6Model.eventName = 'Foredrag 6';
      foredrag6Model.eventDisplayName = 'Varens placering på hylden';
      foredrag6Model.timeSlot = 'Kl. 11.00 – 11.30';
      if (familyDayForedrag6Adults.value !== null
        && familyDayForedrag6Adults.value !== undefined
        && familyDayForedrag6Adults.value !== "0"
        && familyDayForedrag6Adults.value !== ""
        && familyDayForedrag6Adults.value !== "Optaget") {
        foredrag6Model.adults = familyDayForedrag6Adults.value;
        familyDayEventsRegisterationModel.push(foredrag6Model);
      }
      // else {
      //   foredrag6Model.adults = 0;
      // }


    }
    if ((familyDayForedrag7Adults !== null && familyDayForedrag7Adults !== undefined)) {
      let foredrag7Model: FamilyDayEventsRegisterationModel = {};
      foredrag7Model.userId = userId.value;
      foredrag7Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag7Model.eventType = 'Presentation';
      foredrag7Model.eventName = 'Foredrag 7';
      foredrag7Model.eventDisplayName = 'Salling Group – Historien bag succesen';
      foredrag7Model.timeSlot = 'Kl. 11.45 – 12.15';
      if (familyDayForedrag7Adults.value !== null
        && familyDayForedrag7Adults.value !== undefined
        && familyDayForedrag7Adults.value !== "0"
        && familyDayForedrag7Adults.value !== ""
        && familyDayForedrag7Adults.value !== "Optaget") {
        foredrag7Model.adults = familyDayForedrag7Adults.value;
        familyDayEventsRegisterationModel.push(foredrag7Model);
      }
      // else {
      //   foredrag7Model.adults = 0;
      // }


    }
    if ((familyDayForedrag8Adults !== null && familyDayForedrag8Adults !== undefined)) {
      let foredrag8Model: FamilyDayEventsRegisterationModel = {};
      foredrag8Model.userId = userId.value;
      foredrag8Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag8Model.eventType = 'Presentation';
      foredrag8Model.eventName = 'Foredrag 8';
      foredrag8Model.eventDisplayName = 'Salling Fondene – vores bidrag til samfundet';
      foredrag8Model.timeSlot = 'Kl. 11.45 – 12.15';
      if (familyDayForedrag8Adults.value !== null
        && familyDayForedrag8Adults.value !== undefined
        && familyDayForedrag8Adults.value !== "0"
        && familyDayForedrag8Adults.value !== ""
        && familyDayForedrag8Adults.value !== "Optaget") {
        foredrag8Model.adults = familyDayForedrag8Adults.value;
        familyDayEventsRegisterationModel.push(foredrag8Model);
      }
      // else {
      //   foredrag8Model.adults = 0;
      // }


    }
    if ((familyDayForedrag9Adults !== null && familyDayForedrag9Adults !== undefined)) {
      let foredrag9Model: FamilyDayEventsRegisterationModel = {};
      foredrag9Model.userId = userId.value;
      foredrag9Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag9Model.eventType = 'Presentation';
      foredrag9Model.eventName = 'Foredrag 9';
      foredrag9Model.eventDisplayName = 'En bæredygtig fremtid';
      foredrag9Model.timeSlot = 'Kl. 11.45 – 12.15';
      if (familyDayForedrag9Adults.value !== null
        && familyDayForedrag9Adults.value !== undefined
        && familyDayForedrag9Adults.value !== "0"
        && familyDayForedrag9Adults.value !== ""
        && familyDayForedrag9Adults.value !== "Optaget") {
        foredrag9Model.adults = familyDayForedrag9Adults.value;
        familyDayEventsRegisterationModel.push(foredrag9Model);
      }
      // else {
      //   foredrag9Model.adults = 0;
      // }


    }
    if ((familyDayForedrag10Adults !== null && familyDayForedrag10Adults !== undefined)) {
      let foredrag10Model: FamilyDayEventsRegisterationModel = {};
      foredrag10Model.userId = userId.value;
      foredrag10Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag10Model.eventType = 'Presentation';
      foredrag10Model.eventName = 'Foredrag 10';
      foredrag10Model.eventDisplayName = 'Legen er tilbage – det nye BR';
      foredrag10Model.timeSlot = 'Kl. 12.30 – 13.00';
      if (familyDayForedrag10Adults.value !== null
        && familyDayForedrag10Adults.value !== undefined
        && familyDayForedrag10Adults.value !== "0"
        && familyDayForedrag10Adults.value !== ""
        && familyDayForedrag10Adults.value !== "Optaget") {
        foredrag10Model.adults = familyDayForedrag10Adults.value;
        familyDayEventsRegisterationModel.push(foredrag10Model);
      }
      // else {
      //   foredrag10Model.adults = 0;
      // }


    }
    if ((familyDayForedrag11Adults !== null && familyDayForedrag11Adults !== undefined)) {
      let foredrag11Model: FamilyDayEventsRegisterationModel = {};
      foredrag11Model.userId = userId.value;
      foredrag11Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag11Model.eventType = 'Presentation';
      foredrag11Model.eventName = 'Foredrag 11';
      foredrag11Model.eventDisplayName = 'Fra Fjernøsten til hylden i Netto Hirtshals';
      foredrag11Model.timeSlot = 'Kl. 12.30 – 13.00';
      if (familyDayForedrag11Adults.value !== null
        && familyDayForedrag11Adults.value !== undefined
        && familyDayForedrag11Adults.value !== "0"
        && familyDayForedrag11Adults.value !== ""
        && familyDayForedrag11Adults.value !== "Optaget") {
        foredrag11Model.adults = familyDayForedrag11Adults.value;
        familyDayEventsRegisterationModel.push(foredrag11Model);
      }
      // else {
      //   foredrag11Model.adults = 0;
      // }


    }
    if ((familyDayForedrag12Adults !== null && familyDayForedrag12Adults !== undefined)) {
      let foredrag12Model: FamilyDayEventsRegisterationModel = {};
      foredrag12Model.userId = userId.value;
      foredrag12Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag12Model.eventType = 'Presentation';
      foredrag12Model.eventName = 'Foredrag 12';
      foredrag12Model.eventDisplayName = 'Private Label skaber loyalitet';
      foredrag12Model.timeSlot = 'Kl. 12.30 – 13.00';
      if (familyDayForedrag12Adults.value !== null
        && familyDayForedrag12Adults.value !== undefined
        && familyDayForedrag12Adults.value !== "0"
        && familyDayForedrag12Adults.value !== ""
        && familyDayForedrag12Adults.value !== "Optaget") {
        foredrag12Model.adults = familyDayForedrag12Adults.value;
        familyDayEventsRegisterationModel.push(foredrag12Model);
      }
      // else {
      //   foredrag12Model.adults = 0;
      // }


    }
    if ((familyDayForedrag13Adults !== null && familyDayForedrag13Adults !== undefined)) {
      let foredrag13Model: FamilyDayEventsRegisterationModel = {};
      foredrag13Model.userId = userId.value;
      foredrag13Model.familyDayRegistrationId = familDayRegisterationId.value;
      foredrag13Model.eventType = 'Presentation';
      foredrag13Model.eventName = 'Foredrag 13';
      foredrag13Model.eventDisplayName = 'Salling Group – Historien bag succesen';
      foredrag13Model.timeSlot = 'Kl. 13.15 – 13.45';
      if (familyDayForedrag13Adults.value !== null
        && familyDayForedrag13Adults.value !== undefined
        && familyDayForedrag13Adults.value !== "0"
        && familyDayForedrag13Adults.value !== ""
        && familyDayForedrag13Adults.value !== "Optaget") {
        foredrag13Model.adults = familyDayForedrag13Adults.value;
        familyDayEventsRegisterationModel.push(foredrag13Model);

      }
      // else {
      //   foredrag13Model.adults = 0;
      // }

    }
    // if ((familyDayForedrag14Adults !== null && familyDayForedrag14Adults !== undefined) ) {
    //   let foredrag14Model: FamilyDayEventsRegisterationModel = {};
    //   foredrag14Model.userId = userId.value;
    //   foredrag14Model.familyDayRegistrationId = familDayRegisterationId.value;
    //   foredrag14Model.eventType = 'Presentation';
    //   foredrag14Model.eventName = 'Foredrag 14';
    //   foredrag14Model.timeSlot = 'Kl. 13.15 – 13.45';
    //   if (familyDayForedrag14Adults.value !== null && familyDayForedrag14Adults.value !== undefined) {
    //     foredrag14Model.adults = familyDayForedrag14Adults.value;
    //   }
    //   else {
    //     foredrag14Model.adults = 0;
    //   }

    //   familyDayEventsRegisterationModel.push(foredrag14Model);
    // }
    // if ((familyDayForedrag15Adults !== null && familyDayForedrag15Adults !== undefined) ) {
    //   let foredrag15Model: FamilyDayEventsRegisterationModel = {};
    //   foredrag15Model.userId = userId.value;
    //   foredrag15Model.familyDayRegistrationId = familDayRegisterationId.value;
    //   foredrag15Model.eventType = 'Presentation';
    //   foredrag15Model.eventName = 'Foredrag 15';
    //   foredrag15Model.timeSlot = 'Kl. 13.15 – 13.45';
    //   if (familyDayForedrag15Adults.value !== null && familyDayForedrag15Adults.value !== undefined) {
    //     foredrag15Model.adults = familyDayForedrag15Adults.value;
    //   }
    //   else {
    //     foredrag15Model.adults = 0;
    //   }
    //   familyDayEventsRegisterationModel.push(foredrag15Model);
    // }
    if ((familyDayLagerrundtur1Adults !== null && familyDayLagerrundtur1Adults !== undefined)) {
      let lagerrundtur1Model: FamilyDayEventsRegisterationModel = {};
      lagerrundtur1Model.userId = userId.value;
      lagerrundtur1Model.familyDayRegistrationId = familDayRegisterationId.value;
      lagerrundtur1Model.eventType = 'Tour';
      lagerrundtur1Model.eventName = 'Lagerrundtur 1';
      lagerrundtur1Model.eventDisplayName = 'Lagerrundtur 1';
      lagerrundtur1Model.timeSlot = 'Kl. 9.30 – 10.30';
      if (familyDayLagerrundtur1Adults.value !== null
        && familyDayLagerrundtur1Adults.value !== undefined
        && familyDayLagerrundtur1Adults.value !== "0"
        && familyDayLagerrundtur1Adults.value !== ""
        && familyDayLagerrundtur1Adults.value !== "Optaget") {
        lagerrundtur1Model.adults = familyDayLagerrundtur1Adults.value;
        familyDayEventsRegisterationModel.push(lagerrundtur1Model);
      }
      // else {
      //   lagerrundtur1Model.adults = 0;
      // }


    }
    if ((familyDayLagerrundtur2Adults !== null && familyDayLagerrundtur2Adults !== undefined)) {
      let lagerrundtur2Model: FamilyDayEventsRegisterationModel = {};
      lagerrundtur2Model.userId = userId.value;
      lagerrundtur2Model.familyDayRegistrationId = familDayRegisterationId.value;
      lagerrundtur2Model.eventType = 'Tour';
      lagerrundtur2Model.eventName = 'Lagerrundtur 2';
      lagerrundtur2Model.eventDisplayName = 'Lagerrundtur 2';
      lagerrundtur2Model.timeSlot = 'Kl. 10.15 – 11.15';
      if (familyDayLagerrundtur2Adults.value !== null
        && familyDayLagerrundtur2Adults.value !== undefined
        && familyDayLagerrundtur2Adults.value !== "0"
        && familyDayLagerrundtur2Adults.value !== ""
        && familyDayLagerrundtur2Adults.value !== "Optaget") {
        lagerrundtur2Model.adults = familyDayLagerrundtur2Adults.value;
        familyDayEventsRegisterationModel.push(lagerrundtur2Model);
      }
      // else {
      //   lagerrundtur2Model.adults = 0;
      // }


    }
    if ((familyDayLagerrundtur3Adults !== null && familyDayLagerrundtur3Adults !== undefined)) {
      let lagerrundtur3Model: FamilyDayEventsRegisterationModel = {};
      lagerrundtur3Model.userId = userId.value;
      lagerrundtur3Model.familyDayRegistrationId = familDayRegisterationId.value;
      lagerrundtur3Model.eventType = 'Tour';
      lagerrundtur3Model.eventName = 'Lagerrundtur 3';
      lagerrundtur3Model.eventDisplayName = 'Lagerrundtur 3';
      lagerrundtur3Model.timeSlot = 'Kl. 11.00 – 12.00';
      if (familyDayLagerrundtur3Adults.value !== null
        && familyDayLagerrundtur3Adults.value !== undefined
        && familyDayLagerrundtur3Adults.value !== "0"
        && familyDayLagerrundtur3Adults.value !== ""
        && familyDayLagerrundtur3Adults.value !== "Optaget") {
        lagerrundtur3Model.adults = familyDayLagerrundtur3Adults.value;
        familyDayEventsRegisterationModel.push(lagerrundtur3Model);
      }
      // else {
      //   lagerrundtur3Model.adults = 0;
      // }


    }
    if ((familyDayLagerrundtur4Adults !== null && familyDayLagerrundtur4Adults !== undefined)) {
      let lagerrundtur4Model: FamilyDayEventsRegisterationModel = {};
      lagerrundtur4Model.userId = userId.value;
      lagerrundtur4Model.familyDayRegistrationId = familDayRegisterationId.value;
      lagerrundtur4Model.eventType = 'Tour';
      lagerrundtur4Model.eventName = 'Lagerrundtur 4';
      lagerrundtur4Model.eventDisplayName = 'Lagerrundtur 4';
      lagerrundtur4Model.timeSlot = 'Kl. 11.45 – 12.45';
      if (familyDayLagerrundtur4Adults.value !== null
        && familyDayLagerrundtur4Adults.value !== undefined
        && familyDayLagerrundtur4Adults.value !== "0"
        && familyDayLagerrundtur4Adults.value !== ""
        && familyDayLagerrundtur4Adults.value !== "Optaget") {
        lagerrundtur4Model.adults = familyDayLagerrundtur4Adults.value;
        familyDayEventsRegisterationModel.push(lagerrundtur4Model);
      }
      // else {
      //   lagerrundtur4Model.adults = 0;
      // }


    }
    if ((familyDayLagerrundtur5Adults !== null && familyDayLagerrundtur5Adults !== undefined)) {
      let lagerrundtur5Model: FamilyDayEventsRegisterationModel = {};
      lagerrundtur5Model.userId = userId.value;
      lagerrundtur5Model.familyDayRegistrationId = familDayRegisterationId.value;
      lagerrundtur5Model.eventType = 'Tour';
      lagerrundtur5Model.eventName = 'Lagerrundtur 5';
      lagerrundtur5Model.eventDisplayName = 'Lagerrundtur 5';
      lagerrundtur5Model.timeSlot = 'Kl. 12.30 – 13.30';
      if (familyDayLagerrundtur5Adults.value !== null
        && familyDayLagerrundtur5Adults.value !== undefined
        && familyDayLagerrundtur5Adults.value !== "0"
        && familyDayLagerrundtur5Adults.value !== ""
        && familyDayLagerrundtur5Adults.value !== "Optaget") {
        lagerrundtur5Model.adults = familyDayLagerrundtur5Adults.value;
        familyDayEventsRegisterationModel.push(lagerrundtur5Model);
      }
      // else {
      //   lagerrundtur5Model.adults = 0;
      // }


    }
    if ((familyDayLagerrundtur6Adults !== null && familyDayLagerrundtur6Adults !== undefined)) {

      let lagerrundtur6Model: FamilyDayEventsRegisterationModel = {};
      lagerrundtur6Model.userId = userId.value;
      lagerrundtur6Model.familyDayRegistrationId = familDayRegisterationId.value;
      lagerrundtur6Model.eventType = 'Tour';
      lagerrundtur6Model.eventName = 'Lagerrundtur 6';
      lagerrundtur6Model.eventDisplayName = 'Lagerrundtur 6';
      lagerrundtur6Model.timeSlot = 'Kl. 13.15 – 14.15';
      if (familyDayLagerrundtur6Adults.value !== null
        && familyDayLagerrundtur6Adults.value !== undefined
        && familyDayLagerrundtur6Adults.value !== "0"
        && familyDayLagerrundtur6Adults.value !== ""
        && familyDayLagerrundtur6Adults.value !== "Optaget") {
        lagerrundtur6Model.adults = familyDayLagerrundtur6Adults.value;
        familyDayEventsRegisterationModel.push(lagerrundtur6Model);
      }
      // else {
      //   lagerrundtur6Model.adults = 0;
      // }


    }

    //console.log(familyDayEventsRegisterationModel);
    setLoadingProgress(true);
    baseApi.Invitations.creatFamilyDayEventsRegisteration(familyDayEventsRegisterationModel).then(
      (response: any) => {
        //console.log(response);
        setLoadingProgress(false);
        if (response == "En eller flere af de valgte foredrag/rundture er desværre optaget") {
          fetchFamilyDayEventsParticipants();
          alert.error("DIN TILMELDING KAN IKKE GENNEMFØRES DA ET ELLER FLERE AF DE VALGTE FOREDRAG/RUNDTURE ER OPTAGET ELLER OVERSKRIDER DET LEDIGE ANTAL PLADSER");
        }
        else if (response == "Error occured") {
          alert.error("Error occured");
        }
        else {
          handleNext();
        }
      });
  }
  if (loadingProgress) return <Loader loading={loadingProgress}></Loader>
  if (loadPresentationForm) {
    return (
      <>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <React.Fragment>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.00rem", fontWeight: "bold" }}>
                    Kl. 9.15 – 9.45 {totalSelectedParticipants}
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Per Bank */}
                        Salling Group – Historien bag succesen
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayPerBankAdults"
                        value={familyDayPerBankAdults.value}
                        defaultValue={familyDayPerBankAdults.value}
                        onChange={handleChanged}
                        error={!!familyDayPerBankAdults.error}
                        helperText={familyDayPerBankAdults.error}
                        required={familyDayPerBankAdults.required}
                        disabled={perBankDisabled}
                      >

                        {perBankDisabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}
                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayPerBankChildrens"
                        value={familyDayPerBankChildrens.value}
                        defaultValue={familyDayPerBankChildrens.value}
                        onChange={handleChanged}
                        error={!!familyDayPerBankChildrens.error}
                        helperText={familyDayPerBankChildrens.error}
                        required={familyDayPerBankChildrens.required}
                        disabled={perBankDisabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                       
                      </TextField> */}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.00rem", fontWeight: "bold" }}>
                    Kl. 9.30 – 10.30
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Lagerrundtur 1
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayLagerrundtur1Adults"
                        value={familyDayLagerrundtur1Adults.value}
                        defaultValue={familyDayLagerrundtur1Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur1Adults.error}
                        helperText={familyDayLagerrundtur1Adults.error}
                        required={familyDayLagerrundtur1Adults.required}
                        disabled={lagerrundtur1Disabled}
                      >
                        {lagerrundtur1Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}


                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayLagerrundtur1Childrens"
                        value={familyDayLagerrundtur1Childrens.value}
                        defaultValue={familyDayLagerrundtur1Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur1Childrens.error}
                        helperText={familyDayLagerrundtur1Childrens.error}
                        required={familyDayLagerrundtur1Childrens.required}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                       
                      </TextField> */}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <React.Fragment>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.00rem", fontWeight: "bold" }}>
                    Kl. 10.15 – 10.45
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 1 */}
                        Salling Fondene – vores bidrag til samfundet
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag1Adults"
                        value={familyDayForedrag1Adults.value}
                        defaultValue={familyDayForedrag1Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag1Adults.error}
                        helperText={familyDayForedrag1Adults.error}
                        required={familyDayForedrag1Adults.required}
                        disabled={foredrag1Disabled}

                      >
                        {foredrag1Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}
                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag1Childrens"
                        value={familyDayForedrag1Childrens.value}
                        defaultValue={familyDayForedrag1Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag1Childrens.error}
                        helperText={familyDayForedrag1Childrens.error}
                        required={familyDayForedrag1Childrens.required}
                        disabled={foredrag1Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 2 */}
                        Kunden i fokus
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag2Adults"
                        value={familyDayForedrag2Adults.value}
                        defaultValue={familyDayForedrag2Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag2Adults.error}
                        helperText={familyDayForedrag2Adults.error}
                        required={familyDayForedrag2Adults.required}
                        disabled={foredrag2Disabled}
                      >
                        {foredrag2Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag2Childrens"
                        value={familyDayForedrag2Childrens.value}
                        defaultValue={familyDayForedrag2Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag2Childrens.error}
                        helperText={familyDayForedrag2Childrens.error}
                        required={familyDayForedrag2Childrens.required}
                        disabled={foredrag2Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 3 */}
                        Danmarks største arbejdsgiver
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag3Adults"
                        value={familyDayForedrag3Adults.value}
                        defaultValue={familyDayForedrag3Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag3Adults.error}
                        helperText={familyDayForedrag3Adults.error}
                        required={familyDayForedrag3Adults.required}
                        disabled={foredrag3Disabled}
                      >
                        {foredrag3Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag3Childrens"
                        value={familyDayForedrag3Childrens.value}
                        defaultValue={familyDayForedrag3Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag3Childrens.error}
                        helperText={familyDayForedrag3Childrens.error}
                        required={familyDayForedrag3Childrens.required}
                        disabled={foredrag3Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 10.15 – 11.15
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Lagerrundtur 2
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayLagerrundtur2Adults"
                        value={familyDayLagerrundtur2Adults.value}
                        defaultValue={familyDayLagerrundtur2Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur2Adults.error}
                        helperText={familyDayLagerrundtur2Adults.error}
                        required={familyDayLagerrundtur2Adults.required}
                        disabled={lagerrundtur2Disabled}
                      >
                        {lagerrundtur2Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayLagerrundtur2Childrens"
                        value={familyDayLagerrundtur2Childrens.value}
                        defaultValue={familyDayLagerrundtur2Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur2Childrens.error}
                        helperText={familyDayLagerrundtur2Childrens.error}
                        required={familyDayLagerrundtur2Childrens.required}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        
                      </TextField> */}
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <React.Fragment>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 11.00 – 11.30
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 4 */}
                        Netto – Salling Groups frække hund
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag4Adults"
                        value={familyDayForedrag4Adults.value}
                        defaultValue={familyDayForedrag4Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag4Adults.error}
                        helperText={familyDayForedrag4Adults.error}
                        required={familyDayForedrag4Adults.required}
                        disabled={foredrag4Disabled}
                      >
                        {foredrag4Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag4Childrens"
                        value={familyDayForedrag4Childrens.value}
                        defaultValue={familyDayForedrag4Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag4Childrens.error}
                        helperText={familyDayForedrag4Childrens.error}
                        required={familyDayForedrag4Childrens.required}
                        disabled={foredrag4Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 5 */}
                        føtex i et omskifteligt marked
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag5Adults"
                        value={familyDayForedrag5Adults.value}
                        defaultValue={familyDayForedrag5Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag5Adults.error}
                        helperText={familyDayForedrag5Adults.error}
                        required={familyDayForedrag5Adults.required}
                        disabled={foredrag5Disabled}
                      >
                        {foredrag5Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag5Childrens"
                        value={familyDayForedrag5Childrens.value}
                        defaultValue={familyDayForedrag5Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag5Childrens.error}
                        helperText={familyDayForedrag5Childrens.error}
                        required={familyDayForedrag5Childrens.required}
                        disabled={foredrag5Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 6 */}
                        Varens placering på hylden
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag6Adults"
                        value={familyDayForedrag6Adults.value}
                        defaultValue={familyDayForedrag6Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag6Adults.error}
                        helperText={familyDayForedrag6Adults.error}
                        required={familyDayForedrag6Adults.required}
                        disabled={foredrag6Disabled}
                      >
                        {foredrag6Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag6Childrens"
                        value={familyDayForedrag6Childrens.value}
                        defaultValue={familyDayForedrag6Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag6Childrens.error}
                        helperText={familyDayForedrag6Childrens.error}
                        required={familyDayForedrag6Childrens.required}
                        disabled={foredrag6Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 11.00 – 12.00
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Lagerrundtur 3
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayLagerrundtur3Adults"
                        value={familyDayLagerrundtur3Adults.value}
                        defaultValue={familyDayLagerrundtur3Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur3Adults.error}
                        helperText={familyDayLagerrundtur3Adults.error}
                        required={familyDayLagerrundtur3Adults.required}
                        disabled={lagerrundtur3Disabled}
                      >
                        {lagerrundtur3Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayLagerrundtur3Childrens"
                        value={familyDayLagerrundtur3Childrens.value}
                        defaultValue={familyDayLagerrundtur3Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur3Childrens.error}
                        helperText={familyDayLagerrundtur3Childrens.error}
                        required={familyDayLagerrundtur3Childrens.required}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        
                      </TextField> */}
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <React.Fragment>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 11.45 – 12.15
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 7 */}
                        Salling Group – Historien bag succesen
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag7Adults"
                        value={familyDayForedrag7Adults.value}
                        defaultValue={familyDayForedrag7Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag7Adults.error}
                        helperText={familyDayForedrag7Adults.error}
                        required={familyDayForedrag7Adults.required}
                        disabled={foredrag7Disabled}
                      >
                        {foredrag7Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag7Childrens"
                        value={familyDayForedrag7Childrens.value}
                        defaultValue={familyDayForedrag7Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag7Childrens.error}
                        helperText={familyDayForedrag7Childrens.error}
                        required={familyDayForedrag7Childrens.required}
                        disabled={foredrag7Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 8 */}
                        Salling Fondene – vores bidrag til samfundet
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag8Adults"
                        value={familyDayForedrag8Adults.value}
                        defaultValue={familyDayForedrag8Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag8Adults.error}
                        helperText={familyDayForedrag8Adults.error}
                        required={familyDayForedrag8Adults.required}
                        disabled={foredrag8Disabled}
                      >
                        {foredrag8Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag8Childrens"
                        value={familyDayForedrag8Childrens.value}
                        defaultValue={familyDayForedrag8Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag8Childrens.error}
                        helperText={familyDayForedrag8Childrens.error}
                        required={familyDayForedrag8Childrens.required}
                        disabled={foredrag8Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 9 */}
                        En bæredygtig fremtid
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag9Adults"
                        value={familyDayForedrag9Adults.value}
                        defaultValue={familyDayForedrag9Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag9Adults.error}
                        helperText={familyDayForedrag9Adults.error}
                        required={familyDayForedrag9Adults.required}
                        disabled={foredrag9Disabled}
                      >
                        {foredrag9Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag9Childrens"
                        value={familyDayForedrag9Childrens.value}
                        defaultValue={familyDayForedrag9Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag9Childrens.error}
                        helperText={familyDayForedrag9Childrens.error}
                        required={familyDayForedrag9Childrens.required}
                        disabled={foredrag9Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.00rem", fontWeight: "bold" }}>
                    Kl. 11.45 – 12.45
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Lagerrundtur 4
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayLagerrundtur4Adults"
                        value={familyDayLagerrundtur4Adults.value}
                        defaultValue={familyDayLagerrundtur4Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur4Adults.error}
                        helperText={familyDayLagerrundtur4Adults.error}
                        required={familyDayLagerrundtur4Adults.required}
                        disabled={lagerrundtur4Disabled}
                      >
                        {lagerrundtur4Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayLagerrundtur4Childrens"
                        value={familyDayLagerrundtur4Childrens.value}
                        defaultValue={familyDayLagerrundtur4Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur4Childrens.error}
                        helperText={familyDayLagerrundtur4Childrens.error}
                        required={familyDayLagerrundtur4Childrens.required}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        
                      </TextField> */}
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <React.Fragment>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 12.30 – 13.00
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 10 */}
                        Legen er tilbage – det nye BR
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag10Adults"
                        value={familyDayForedrag10Adults.value}
                        defaultValue={familyDayForedrag10Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag10Adults.error}
                        helperText={familyDayForedrag10Adults.error}
                        required={familyDayForedrag10Adults.required}
                        disabled={foredrag10Disabled}
                      >
                        {foredrag10Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag10Childrens"
                        value={familyDayForedrag10Childrens.value}
                        defaultValue={familyDayForedrag10Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag10Childrens.error}
                        helperText={familyDayForedrag10Childrens.error}
                        required={familyDayForedrag10Childrens.required}
                        disabled={foredrag10Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 11 */}
                        Fra Fjernøsten til hylden i Netto Hirtshals
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag11Adults"
                        value={familyDayForedrag11Adults.value}
                        defaultValue={familyDayForedrag11Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag11Adults.error}
                        helperText={familyDayForedrag11Adults.error}
                        required={familyDayForedrag11Adults.required}
                        disabled={foredrag11Disabled}
                      >
                        {foredrag11Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag11Childrens"
                        value={familyDayForedrag11Childrens.value}
                        defaultValue={familyDayForedrag11Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag11Childrens.error}
                        helperText={familyDayForedrag11Childrens.error}
                        required={familyDayForedrag11Childrens.required}
                        disabled={foredrag11Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 12 */}
                        Private Label skaber loyalitet
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag12Adults"
                        value={familyDayForedrag12Adults.value}
                        defaultValue={familyDayForedrag12Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag12Adults.error}
                        helperText={familyDayForedrag12Adults.error}
                        required={familyDayForedrag12Adults.required}
                        disabled={foredrag12Disabled}
                      >
                        {foredrag12Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag12Childrens"
                        value={familyDayForedrag12Childrens.value}
                        defaultValue={familyDayForedrag12Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag12Childrens.error}
                        helperText={familyDayForedrag12Childrens.error}
                        required={familyDayForedrag12Childrens.required}
                        disabled={foredrag12Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                      </TextField>
                    </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 12.30 – 13.30
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Lagerrundtur 5
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayLagerrundtur5Adults"
                        value={familyDayLagerrundtur5Adults.value}
                        defaultValue={familyDayLagerrundtur5Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur5Adults.error}
                        helperText={familyDayLagerrundtur5Adults.error}
                        required={familyDayLagerrundtur5Adults.required}
                        disabled={lagerrundtur5Disabled}
                      >
                        {lagerrundtur5Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayLagerrundtur5Childrens"
                        value={familyDayLagerrundtur5Childrens.value}
                        defaultValue={familyDayLagerrundtur5Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur5Childrens.error}
                        helperText={familyDayLagerrundtur5Childrens.error}
                        required={familyDayLagerrundtur5Childrens.required}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        
                      </TextField> */}
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <React.Fragment>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 13.15 – 13.45
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        {/* Foredrag 13 */}
                        Salling Group – Historien bag succesen
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag13Adults"
                        value={familyDayForedrag13Adults.value}
                        defaultValue={familyDayForedrag13Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag13Adults.error}
                        helperText={familyDayForedrag13Adults.error}
                        required={familyDayForedrag13Adults.required}
                        disabled={foredrag13Disabled}
                      >
                        {foredrag13Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag13Childrens"
                        value={familyDayForedrag13Childrens.value}
                        defaultValue={familyDayForedrag13Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag13Childrens.error}
                        helperText={familyDayForedrag13Childrens.error}
                        required={familyDayForedrag13Childrens.required}
                        disabled={foredrag13Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    {/* <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Foredrag 14
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag14Adults"
                        value={familyDayForedrag14Adults.value}
                        defaultValue={familyDayForedrag14Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag14Adults.error}
                        helperText={familyDayForedrag14Adults.error}
                        required={familyDayForedrag14Adults.required}
                        disabled={foredrag14Disabled}
                      >

                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option>

                      </TextField>
                    </Grid> */}
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag14Childrens"
                        value={familyDayForedrag14Childrens.value}
                        defaultValue={familyDayForedrag14Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag14Childrens.error}
                        helperText={familyDayForedrag14Childrens.error}
                        required={familyDayForedrag14Childrens.required}
                        disabled={foredrag14Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    {/* <Grid item xs={6} >
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                         Foredrag 15
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayForedrag15Adults"
                        value={familyDayForedrag15Adults.value}
                        defaultValue={familyDayForedrag15Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag15Adults.error}
                        helperText={familyDayForedrag15Adults.error}
                        required={familyDayForedrag15Adults.required}
                        disabled={foredrag15Disabled}
                      >

                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option>

                      </TextField>
                    </Grid> */}
                    {/* <Grid item xs={5} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayForedrag15Childrens"
                        value={familyDayForedrag15Childrens.value}
                        defaultValue={familyDayForedrag15Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayForedrag15Childrens.error}
                        helperText={familyDayForedrag15Childrens.error}
                        required={familyDayForedrag15Childrens.required}
                        disabled={foredrag15Disabled}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>


                      </TextField>
                    </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                    Kl. 13.15 – 14.15
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: "0.80rem", fontWeight: "bold" }}>
                        Lagerrundtur 6
                      </Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal voksne"
                        name="familyDayLagerrundtur6Adults"
                        value={familyDayLagerrundtur6Adults.value}
                        defaultValue={familyDayLagerrundtur6Adults.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur6Adults.error}
                        helperText={familyDayLagerrundtur6Adults.error}
                        required={familyDayLagerrundtur6Adults.required}
                        disabled={lagerrundtur6Disabled}
                      >
                        {lagerrundtur6Disabled == false ? options : <option value="Optaget">{"Optaget"}</option>}
                        {/* <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        <option value="5">{"5"}</option> */}

                      </TextField>
                    </Grid>
                    <Grid item xs={5} >
                      {/* <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="Antal børn (under 12)"
                        name="familyDayLagerrundtur6Childrens"
                        value={familyDayLagerrundtur6Childrens.value}
                        defaultValue={familyDayLagerrundtur6Childrens.value}
                        onChange={handleChanged}
                        error={!!familyDayLagerrundtur6Childrens.error}
                        helperText={familyDayLagerrundtur6Childrens.error}
                        required={familyDayLagerrundtur6Childrens.required}
                      >
                        <option value="0">{"0"}</option>
                        <option value="1">{"1"}</option>
                        <option value="2">{"2"}</option>
                        <option value="3">{"3"}</option>
                        <option value="4">{"4"}</option>
                        
                      </TextField> */}
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            marginTop: 50,
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="default"
            onClick={backButtonHandler}
            style={{ marginRight: 10 }}
          >
            TILBAGE
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={nextTabHandler}
          >
            GEM TILMELDING
          </Button>
        </div>

      </>
    );
  }
  else {
    return (
      <>
        <div className={classes.box}>
          <Typography variant="h6" align="center">
            <div>Please Sign up Family Day Registration First <Link href="https://invitation-dev-app.azurewebsites.net/FamilyDaySignUp">Family Day Sign up</Link></div>
          </Typography>
        </div>
      </>
    )

  }

}
