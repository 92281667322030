import React, { useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3)
  },
  title: {
    marginTop: 30
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }
}))

export default function CloseInvitation() {
  const classes = useStyles();
  
  return (
    <>

    <div className={classes.box}>
      <Typography variant="h6" align="center">
        Invitation has been closed for now!
      </Typography>
        
    </div>
      </>
  )
}
