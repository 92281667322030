import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import InvitationStepForm from "./InvitationStepForm";
import CloseInvitation from "../CloseInvitation";
import Loading from '../Loading';
import { StepsProvider } from "../Context";
import { DateTime } from "luxon";
import baseApi from "../../_api/base.api";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
}));

export default function InvitationComponent() {
  const classes = useStyles();
  const [isExpire, setIsExpire] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getExpiryTimes("Invitation");
  }, [isExpire]);

  const getExpiryTimes = async (pageName: string) => {
    baseApi.Invitations.GetExpiryTimes(pageName).then((response: any) => {
      if (response) {

        var expiry = response.expiry;
        var currDateTime = DateTime.fromISO(DateTime.now().toISO());
        // var expireDate = DateTime.fromISO('2021-11-4');
        var expireDate = DateTime.fromISO(expiry);
        //console.log(expireDate >= currDateTime);
        if (expireDate >= currDateTime) {
          setIsExpire(false);
        }
        setIsLoading(false);
      }
    });
  }

  if (isExpire && isLoading == false)
  {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <CloseInvitation />
          </Paper>
        </main>
      </>
    );
  }
  else if(!isExpire && isLoading == false)
  {
    return (
      <>
        <StepsProvider>
          <CssBaseline />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <InvitationStepForm />
            </Paper>
          </main>
        </StepsProvider>
      </>
    );
  }
  else
  {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Loading />
          </Paper>
        </main>

      </>
    );
  }
 
}
