import React, { } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { ErrorComponent } from '../layout/ErrorComponent';
import { Loading } from '../layout/Loading';
import { loginRequest } from '../../_utils/authConfig';

export const PageLayout: React.FC<{}> = (props) => {

  const authRequest = {...loginRequest};
 // const authRequest = { ...loginRequest };

  // const { login, result, error } = useMsalAuthentication(
  //   InteractionType.Redirect,
  //   authRequest
  // );

  // useEffect(() => {
  //   if (error) {
  //     login(InteractionType.Redirect, authRequest);
  //   }
  // }, [error]);
 
    return (
      <>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}
        >
          {props.children}
        </MsalAuthenticationTemplate>
      </>
    );
};