

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps:any = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const options:string[]= [
  "Ingen præferencer",
  "Vegetarisk",
  "Vegansk",
  "Laktosefri",
  "Glutenfri",
  "Nøddefri",
  "Spiser ikke svinekød"
];

export {  MenuProps, options };
