import { ValidationSchema } from './Context'

export const initialValues: ValidationSchema = {
  userId: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  userName: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  userEmail: {
    value: '',
    error: '',
    required: false,
    validate: 'email',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  adults: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  zeroToOne: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  twoYear: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  threeToFiveDreng: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  threeToFivePige: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  sixToEightDreng: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  sixToEightPige: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  nineToTwelveDreng: {
    value: '',
    error: '',
    required: false,
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  nineToTwelvePige: {
    value: '',
    error: '',
    required: false,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  childrenCount: {
    value: '',
    error: '',
    required: true,
    minLength: 1,
    maxLength: 5,
    helperText: 'Custom error message'
  },
  childrenAgeGroup: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'

  },
  gender: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'

  },
  department: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'

  },
  email: {
    value: '',
    error: '',
    required: true,
    validate: 'email'
  },
  telephone: {
    value: '',
    error: '',
    required: true,
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  date: {
    value: '',
    error: ''
  },
  transport: {
    value: '',
    error: ''
  },
  familDayRegisterationId: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 500,
    helperText: 'Custom error message'
  },
  familyDayDepartment: {
    value: '',
    error: '',
    required: true,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayAdults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayChildsUnder12: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familDayEventsRegisterationId: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  familyDayPerBankAdults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayPerBankChildrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag1Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag1Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag2Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag2Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag3Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag3Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag4Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag4Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag5Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag5Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag6Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag6Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag7Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag7Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag8Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag8Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag9Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag9Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag10Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag10Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag11Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag11Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag12Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag12Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag13Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag13Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag14Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag14Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag15Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayForedrag15Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur1Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur1Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur2Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur2Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur3Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur3Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur4Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur4Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur5Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur5Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur6Adults: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  familyDayLagerrundtur6Childrens: {
    value: '',
    error: '',
    required: false,
    validate: 'select',
    helperText: 'Custom error message'
  },
  agp_UserTitle: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  agp_UserWarehouseNo: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  agp_UserDietaryPref: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  agp_CompanionDietaryPref: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'
  },
  agp_CompanionUserName: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  agp_CompanionEmail: {
    value: '',
    error: '',
    required: false,
    validate: 'email',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  agp_CompanionTelephone: {
    value: '',
    error: '',
    required: true,
    minLength: 1,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  agp_CompanionInvitedBy: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  agp_CompanionRelationship: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  agp_legalAcknowledgment: {
    value: '',
    error: ''
  },
  agp_UserRole: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  employeeTShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'
  },

  firstParentName: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  firstParentGender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  firstParentBirthday: {
    value: '',
    error: ''
  },
  firstParentTShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  secondParentName: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  secondParentGender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  secondParentBirthday: {
    value: '',
    error: ''
  },
  secondParentTShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child1Name: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  child1Gender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child1Birthday: {
    value: '',
    error: ''
  },
  child1TShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child2Name: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  child2Gender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child2Birthday: {
    value: '',
    error: ''
  },
  child2TShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child3Name: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  child3Gender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child3Birthday: {
    value: '',
    error: ''
  },
  child3TShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child4Name: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  child4Gender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child4Birthday: {
    value: '',
    error: ''
  },
  child4TShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child5Name: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 250,
    helperText: 'Custom error message'
  },
  child5Gender: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  child5Birthday: {
    value: '',
    error: ''
  },
  child5TShirtSize: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  transportRequired: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'
  },
  driveYourself: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
    helperText: 'Custom error message'
  },
  taxCoverage: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
    helperText: 'Custom error message'
  },
  cateringComments: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 500,
    helperText: 'Custom error message'
  },
  generalComments: {
    value: '',
    error: '',
    required: false,
    validate: 'text',
    minLength: 1,
    maxLength: 500,
    helperText: 'Custom error message'
  },
}