import React, { useCallback, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
//import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../Context'
//import { List, Divider, ListItem, ListItemText, Fab } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function SMC_Acknowledgment() {

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin,
    listChildren,
    addChildren
  } = useContext(AppContext);


  const {transportRequired,driveYourself,taxCoverage,cateringComments,generalComments} = formValues;

  const isError = useCallback(
    () =>
      Object.keys({transportRequired,driveYourself,taxCoverage,cateringComments,generalComments}).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues,transportRequired,driveYourself,taxCoverage,cateringComments,generalComments]
  );


  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
           <TextField
                variant={variant}
                margin={margin}
                fullWidth
                select
                SelectProps={{
                native: true,
                }}
                label="Do you want to apply for transport ?"
                name="transportRequired"
                value={transportRequired.value}
                onChange={handleChange}
                error={!!transportRequired.error}
                helperText={transportRequired.error}
                required={transportRequired.required}
            >
                <option value=""></option>
                <option value="Yes">{"Yes"}</option>
                <option value="No">{"No"}</option>
            </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Drive yourself ?"
            name="driveYourself"
            value={driveYourself.value}
            onChange={handleChange}
            error={!!driveYourself.error}
            helperText={driveYourself.error}
            required={driveYourself.required}
          >
            <option value=""></option>
            <option value="Yes">{"Yes"}</option>
            <option value="No">{"No"}</option>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        
        <Grid item xs={12}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Do you want to apply for tax coverage ?"
            name="taxCoverage"
            value={taxCoverage.value}
            onChange={handleChange}
            error={!!taxCoverage.error}
            helperText={taxCoverage.error}
            required={taxCoverage.required}
          >
            <option value=""></option>
            <option value="Yes">{"Yes"}</option>
            <option value="No">{"No"}</option>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Catering: Are there any allergies or anything else that we need to take into account ?
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
            <Grid item xs={12}>
            <TextField
                type="text"
                variant={variant}
                margin={margin}
                fullWidth
                label="Comments"
                name="cateringComments"
                placeholder=""
                value={cateringComments.value}
                defaultValue={cateringComments.value}
                onChange={handleChange}
                error={!!cateringComments.error}
                helperText={cateringComments.error}
                required={cateringComments.required}
            />
            </Grid>
      
        </Grid>
     
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" disabled={isError()} onClick={!isError() ? handleNext : () => null} >
          Next
        </Button>
      </div>
    </>
  );
}
