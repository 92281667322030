import { Configuration, PopupRequest } from "@azure/msal-browser";
const baseUri = document.getElementById('root')?.baseURI;
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        
        clientId: "a3573e11-f802-49bc-b8e3-fbadbb3e86d4",
        authority: "https://login.microsoftonline.com/a9d3abc9-3302-4217-8969-726e46809c6d",
        redirectUri:baseUri,
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/beta/me",
    graphUsersEndpoint: "https://graph.microsoft.com/beta/users"
};
