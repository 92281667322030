import React, { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { AppContext, StepsProvider } from '../Context'
import { useAccount, useMsal } from '@azure/msal-react'
import { UserProfile } from '../../_models/common/profile.model'
import { callMsGraph } from '../../_utils/MsGraphApiCall';

// Applied to all fields
const variant = "outlined";
const margin = "normal";

export default function BarForm() {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [onPremisesSamAccountName, setOnPremisesSamAccountName] = useState('');
    const [transportRequired, setTransportRequired] = useState(false);
    const { formValues, handleChange, handleNext, variant, margin } = useContext(AppContext);
    const { userId, userName, department, email, telephone, transport } = formValues;

    useEffect(() => {

        fetchUserProfile();
        transport.value = transportRequired ? "Yes" : "No";
        email.value = account?.username ? account?.username : '';


    }, [account]);

    async function fetchUserProfile() {
        if (account) {
            instance
                .acquireTokenSilent({
                    scopes: ["User.Read"],
                    account: account,
                })
                .then((response) => {
                    if (response) {
                        callMsGraph(response.accessToken).then((response) => {
                            const profile: UserProfile = response as UserProfile;
                            if (profile !== null || profile !== undefined) {
                                //console.log(profile);
                                setOnPremisesSamAccountName(profile.onPremisesSamAccountName);
                                userId.value = profile.onPremisesSamAccountName;
                                userName.value = profile.displayName;
                                email.value = profile.mail;
                                telephone.value = profile.mobilePhone;


                            }
                        });
                    }
                });
        }
    }
    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ userId, userName, department, email, telephone }).some(
                (name) =>
                    (formValues[name].required && !formValues[name].value) ||
                    formValues[name].error
            ),
        [formValues, userId, userName, department, email, telephone]
    );
    const handleTransportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTransportRequired(event.target.checked);
        transport.value = event.target.checked ? "Yes" : "No";
    };

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="User ID"
                        name="userId"
                        placeholder="Enter user id"
                        value={onPremisesSamAccountName}
                        onChange={handleChange}
                        error={!!userId.error}
                        helperText={userId.error}
                        required={userId.required}
                        inputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Name"
                        name="userName"
                        placeholder="Enter name"
                        value={account?.name}
                        onChange={handleChange}
                        error={!!userName.error}
                        helperText={userName.error}
                        required={userName.required}
                        inputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                            native: true,
                        }}
                        label="Department"
                        name="department"
                        value={department.value}
                        onChange={handleChange}
                        error={!!department.error}
                        helperText={department.error}
                        required={department.required}
                    >
                        <option value=""></option>
                        <option value="Business Support">{"Business Support"}</option>
                        <option value="Commercial">{"Commercial"}</option>
                        <option value="Finance">{"Finance"}</option>
                        <option value="IT & Digital">{"IT & Digital"}</option>
                        <option value="Technical department">{"Technical department"}</option>
                        <option value="føtex">{"føtex"}</option>
                        <option value="People, Culture & Sustainability + Campus Bøgehøj">{"People, Culture & Sustainability + Campus Bøgehøj"}</option>
                        <option value="Supply Chain & Logistics">{"Supply Chain & Logistics"}</option>
                        <option value="Bilka/BR">{"Bilka/BR"}</option>
                        <option value="Salling Group">{"Salling Group"}</option>
                        <option value="Legal & Property">{"Legal & Property"}</option>
                        <option value="Kundeservice Tilst">{"Kundeservice Tilst"}</option>

                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Email"
                        name="email"
                        placeholder="enter email"
                        value={email.value}
                        onChange={handleChange}
                        error={!!email.error}
                        helperText={email.error}
                        required={email.required}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Phone"
                        name="telephone"
                        placeholder="enter telefonnummer"
                        value={telephone.value}
                        onChange={handleChange}
                        error={!!telephone.error}
                        helperText={telephone.error}
                        required={telephone.required}
                    />
                </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs zeroMinWidth >
                    <Typography variant="button" display="block" gutterBottom>
                        Do you want bus transport to Aarhus C?
                    </Typography>
                </Grid>
                <Grid item >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={transportRequired}
                                onChange={handleTransportChange}
                                name="transport"
                                color="primary"
                            />
                        }
                        value={transportRequired ? "Yes" : "No"}
                        label={transportRequired ? "Yes" : "No"}
                        labelPlacement={transportRequired ? "start" : "end"}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    display: "flex",
                    marginTop: 50,
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isError()}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>

        </>
    );
}
