import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import Confirm from './Confirm'
import { AppContext } from '../Context'

import Success from './Success';

// Step titles
const labels = ['User Info', 'Participants Details'];

const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <FirstStep />
    case 1:
      return <SecondStep />
    default:
      throw new Error('Unknown step')
  }
}


export default function InvitationStepForm() {

    const { activeStep } = useContext(AppContext);
 
    return (
      <div>
        
        <section className="content">
          <div className="container-fluid">
            <>
              {activeStep === labels.length ? (
                <Success />
                
              ) : (
                <>
                  <Stepper
                    activeStep={activeStep}
                    style={{ margin: "30px 0 15px" }}
                    alternativeLabel
                  >
                    {labels.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {handleSteps(activeStep)}
                </>
              )}
            </>
          </div>
        </section>
      </div>
    );
}
