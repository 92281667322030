import React, { useEffect, useState, useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import { StepsProvider } from '../Context'
import AnnualGraduationPartyStepForm from './Agp_StepForm';
import CloseInvitation from '../CloseInvitation';
import Loading from '../Loading';
import { DateTime } from "luxon";
import baseApi from '../../_api/base.api';
import { useAccount, useMsal } from '@azure/msal-react';
import { AppContext } from '../Context';
import { UserProfile } from '../../_models/common/profile.model'
import { Grid, Typography } from '@material-ui/core';
import salling_84A6542 from '../salling_84A6542.jpg';
import { callMsGraph } from '../../_utils/MsGraphApiCall'
import Loader from '../common/loader/loader'

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(1000 + theme.spacing(1) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1)
    }
  },
  box: {
    padding: theme.spacing(3)
  },
  title: {
    marginTop: 30
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }
}))

export default function AnnualGraduationPartyComponent() {

  const classes = useStyles();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userProfileData, setUserProfileData] = useState<UserProfile>();
  const [samAccountName,setSamAccountName] = useState('');
  const [isExpire, setIsExpire] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorize, setIsAuthorize] = useState(true);
  const { formValues } = useContext(AppContext);
  const { userId, userName, email, telephone, agp_UserRole, agp_UserTitle, agp_UserWarehouseNo, agp_UserDietaryPref, agp_CompanionUserName, agp_CompanionEmail, agp_CompanionTelephone, agp_CompanionInvitedBy, agp_CompanionRelationship, agp_CompanionDietaryPref } = formValues;

  useEffect(() => {

    // const profile = localStorage.getItem("UserProfile");
    // let userProfile: UserProfile = {} as UserProfile;
    // userProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

    getExpiryTimes("AnnualGraduationParty");
   // console.log(account);
    fetchUserProfile(account);

  }, []);

  const fetchUserProfile = async (account: any) => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            callMsGraph(response.accessToken).then((response) => {
              
              const profile: UserProfile = response as UserProfile;
              if (profile !== null && profile !== undefined) {
                //localStorage.setItem("UserProfile", JSON.stringify(profile));
                  
                let onPremisesSamAccountName = profile.onPremisesSamAccountName;

                //console.log(profile.onPremisesSamAccountName);
                setSamAccountName(onPremisesSamAccountName);
                userId.value = onPremisesSamAccountName;
                fetchUserInfo(onPremisesSamAccountName);
                fetchRegistration(onPremisesSamAccountName);
                setUserProfileData(profile);

                //fetchUserInfo("287228");
                //fetchRegistration("287228");
              }
            });
          }
        })
    }
  }

  const fetchUserInfo = async (userId: string) => {
    setIsLoading(true);
    baseApi.Invitations.GetAnnualGraduationPartyUser(userId).then(
      (response: any) => {
        if (response) {
          if (response !== null) {
            
            setIsAuthorize(false);
            userName.value = response.name;
            email.value = response.email;
            telephone.value = response.telephone;
            agp_UserTitle.value = response?.title ? response?.title : '';
            agp_UserWarehouseNo.value = response?.warehouseNo ? response?.warehouseNo : '';
            agp_UserRole.value = response?.role ? response?.role : '';

          }
        }
        setIsLoading(false);
      }).catch();
  }
  const fetchRegistration = async (userId: string) => {
    setIsLoading(true);
    baseApi.Invitations.GetAnnualGraduationPartyRegisteration(userId).then(
      (response: any) => {
        if (response) {
          if (response !== null) {
            //console.log(response);
            if (response.userDietaryPref !== null && response.userDietaryPref !== undefined) {
              agp_UserDietaryPref.value = response.userDietaryPref;
            }
            if (response.companionUserName !== null && response.companionUserName !== undefined) {
              agp_CompanionUserName.value = response.companionUserName;
            }
            if (response.companionEmail !== null && response.companionEmail !== undefined) {
              agp_CompanionEmail.value = response.companionEmail;
            }
            if (response.companionTelephone !== null && response.companionTelephone !== undefined) {
              agp_CompanionTelephone.value = response.companionTelephone;
            }
            if (response.companionInvitedBy !== null && response.companionInvitedBy !== undefined) {
              agp_CompanionInvitedBy.value = response.companionInvitedBy;
            }
            if (response.companionRelationship !== null && response.companionRelationship !== undefined) {
              agp_CompanionRelationship.value = response.companionRelationship;
            }
            if (response.companionDietaryPref !== null && response.companionDietaryPref !== undefined) {
              agp_CompanionDietaryPref.value = response.companionDietaryPref;
            }
            // if (response.legalStatmentAcknowledgment !== null && response.legalStatmentAcknowledgment !== undefined) {
            //   agp_legalAcknowledgment.value = response.legalStatmentAcknowledgment;
            // }


          }

        }

        setIsLoading(false);
      });
  }
  const getExpiryTimes = async (pageName: string) => {
    baseApi.Invitations.GetExpiryTimes(pageName).then((response: any) => {
      if (response) {

        var expiry = response.expiry;
        var currDateTime = DateTime.fromISO(DateTime.now().toISO());
        //var expireDate = DateTime.fromISO('2021-11-4');
        var expireDate = DateTime.fromISO(expiry);
        //console.log(expireDate >= currDateTime);
        if (expireDate >= currDateTime) {
          setIsExpire(false);
        }
        setIsLoading(false)
      }
    });
  }
  if (isLoading) return <Loader loading={isLoading}></Loader>
  if (isExpire) {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <CloseInvitation />
          </Paper>
        </main>

      </>
    );
  }
  else if (isAuthorize) {
    return (
      <StepsProvider>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.box}>
              <Typography variant="h6" align="center">
                Du {samAccountName} er desværre ikke på listen over inviterede og kan derfor ikke tilmelde dig dimissionsfesten {DateTime.now().year}
              </Typography>

            </div>
          </Paper>
        </main>
      </StepsProvider>
    )
  }
  else {
    return (

      <StepsProvider>
        <CssBaseline />
        <main className={classes.layout} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <img src={salling_84A6542} alt="" style={{ maxWidth: "100%" }}  ></img>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <AnnualGraduationPartyStepForm userProfileData={userProfileData || {}} />
          </Paper>
        </main>
      </StepsProvider>

    )
  }
}
