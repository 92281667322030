import React, { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { AppContext } from '../Context'
import { useAccount, useMsal } from '@azure/msal-react'
import { UserProfile } from '../../_models/common/profile.model'
import baseApi from '../../_api/base.api';
import { FamilyDayRegistrationModel } from "../../_models/invitation/FamilyDayRegistratoin.model";
import { useAlert } from "react-alert";

// Applied to all fields
const variant = "outlined";
const margin = "normal";

interface Props {
  familyDayRegistration: FamilyDayRegistrationModel;
}
export default function FamilyDayForm({ familyDayRegistration }: Props) {

  const alert = useAlert();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [onPremisesSamAccountName, setOnPremisesSamAccountName] = useState('');
  const [userRegistration, setuserRegistration] = useState<FamilyDayRegistrationModel>(familyDayRegistration);
  const { formValues, handleChange, handleNext, variant, margin } = useContext(AppContext);
  const { userId, userName, email, familyDayDepartment, familyDayAdults, familyDayChildsUnder12 } = formValues;

  useEffect(() => {

    const profile = localStorage.getItem("UserProfile");
    let userProfile: UserProfile = {} as UserProfile;
    userProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

    setOnPremisesSamAccountName(userProfile.onPremisesSamAccountName);
    userId.value = userProfile.onPremisesSamAccountName;
    userName.value = userProfile.displayName;
    email.value = userProfile.mail;

    function fetchRegistration(userId: string) {

      baseApi.Invitations.GetFamilyDayRegistration(userId).then(
        (response: FamilyDayRegistrationModel) => {
          if (response) {
            familyDayDepartment.value = response.afdeling;
            familyDayAdults.value = response.numberOfAdults;
            familyDayChildsUnder12.value = response.numberOfChildsUnder12;
          }
        });
    }

    fetchRegistration(userProfile.onPremisesSamAccountName);


  }, []);


  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({ userId, userName, email, familyDayDepartment, familyDayAdults, familyDayChildsUnder12 }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, userId, userName, email, familyDayDepartment, familyDayAdults, familyDayChildsUnder12]
  );

  const handleChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {

    if (event.target.name == "familyDayAdults") {
      if (event.target.value !== undefined && event.target.value !== null) {
        if (Number(familyDayChildsUnder12.value) + Number(event.target.value) > 5) {
          alert.show('Du kan kun tilmelde 4 personer.');
        }
        else {
          handleChange(event);
        }
      }
    }
    if (event.target.name == "familyDayChildsUnder12") {
      if (event.target.value !== undefined && event.target.value !== null) {
        if (Number(familyDayAdults.value) + Number(event.target.value) > 5) {
          alert.show('Du kan kun tilmelde 4 personer.');
        }
        else {
          handleChange(event);
        }
      }
    }

    //console.log(event.target.value);
    //handleChange(event);

  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="User ID"
            name="userId"
            placeholder=""
            value={onPremisesSamAccountName}
            defaultValue={onPremisesSamAccountName}
            onChange={handleChange}
            error={!!userId.error}
            helperText={userId.error}
            required={userId.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Name"
            name="userName"
            placeholder=""
            value={account?.name}
            defaultValue={account?.name}
            onChange={handleChange}
            error={!!userName.error}
            helperText={userName.error}
            required={userName.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Email"
            name="email"
            placeholder=""
            value={account?.username}
            defaultValue={account?.username}
            onChange={handleChange}
            error={!!email.error}
            helperText={email.error}
            required={email.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Butik/Afdeling"
            name="familyDayDepartment"
            value={familyDayDepartment.value}
            onChange={handleChange}
            error={!!familyDayDepartment.error}
            helperText={familyDayDepartment.error}
            required={familyDayDepartment.required}
          >
            <option value=""></option>
            <option value="Bilka/BR">{"Bilka/BR"}</option>
            <option value="Commercial">{"Commercial"}</option>
            <option value="Customer service (Tilst)">{"Customer service (Tilst)"}</option>
            <option value="E-commerce/Digital">{"E-commerce/Digital"}</option>
            <option value="Finance/BD/IA">{"Finance/BD/IA"}</option>
            <option value="føtex">{"føtex"}</option>
            <option value="HR/Communication">{"HR/Communication"}</option>
            <option value="ITS">{"ITS"}</option>
            <option value="Intern Service">{"Intern Service"}</option>
            <option value="Legal">{"Legal"}</option>
            <option value="Salling Group">{"Salling Group"}</option>
            <option value="Supply chain">{"Supply chain"}</option>
            <option value="Technical Affairs">{"Technical Affairs"}</option>
           </TextField>

        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Antal voksne"
            name="familyDayAdults"
            value={familyDayAdults.value}
            defaultValue={familyDayAdults.value}
            onChange={handleChanged}
            error={!!familyDayAdults.error}
            helperText={familyDayAdults.error}
            required={familyDayAdults.required}
          >
            
            <option value="1">{"1"}</option>
            <option value="2">{"2"}</option>
            <option value="3">{"3"}</option>
            <option value="4">{"4"}</option>
            <option value="5">{"5"}</option>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Antal børn (under 14 år)"
            name="familyDayChildsUnder12"
            value={familyDayChildsUnder12.value}
            defaultValue={familyDayChildsUnder12.value}
            onChange={handleChanged}
            error={!!familyDayChildsUnder12.error}
            helperText={familyDayChildsUnder12.error}
            required={familyDayChildsUnder12.required}
          >
            <option value="0">{"0"}</option>
            <option value="1">{"1"}</option>
            <option value="2">{"2"}</option>
            <option value="3">{"3"}</option>
            <option value="4">{"4"}</option>
          </TextField>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          marginTop: 50,
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isError()}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>

    </>
  );
}
