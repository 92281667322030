import {  graphConfig } from "./authConfig";

export async function callMsGraph(accessToken:string) {
    
    //console.log(accessToken);
    // const account = msalInstance.getActiveAccount();
    // if (!account) {
    //     throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    // }

    // const response = await msalInstance.acquireTokenSilent({
    //     ...loginRequest,
    //     account: account
    // });
    
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
// export function getProfile(email:string){
//     //email = 'imran.ali@sallinggroup.com';
//    let url: string = "https://graph.microsoft.com/beta/users?$filter=userPrincipalName eq '"+  email +"'";
//    return requests.get<any>(url)
//    .then(response => response.json())
//    .catch(error => console.log(error));
//  } 