import React, {  useContext, useState} from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { AppContext } from '../Context'
import baseApi from '../../_api/base.api';
import { useAccount, useMsal } from '@azure/msal-react'

// interface Props {
//   familyDayRegistration: FamilyDayRegistrationModel;
// }
export interface FamilyDayRegistrationModel {
  Id:number,
  userId: string;
  userName: string;
  email: string;
  afdeling: string;
  numberofAdults: string;
  numberofChildsUnder12: string;
  status:number
}
export default function FamilyDayConfirm() {

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { formValues, handleBack, handleNext} = useContext(AppContext);
  const { userId, userName,email,familyDayDepartment,familyDayAdults,familyDayChildsUnder12} = formValues;
 

  const handleSubmit = () => {

    let Id:number = 0;
    let status:number = 0;

    
    // if(familyDayRegistration != undefined && familyDayRegistration != null)
    // {

    //   status = 1;
    //   Id = familyDayRegistration.Id;
     
    // }

    let registrationModel:FamilyDayRegistrationModel = {
  
      Id:Id,
      userId : userId.value,
      userName : userName.value,
      afdeling :familyDayDepartment.value,
      email : email.value,
      numberofAdults : familyDayAdults.value ? familyDayAdults.value.toString() : "1",
      numberofChildsUnder12 : familyDayChildsUnder12.value ? familyDayChildsUnder12.value.toString() : "0",
      status : status

    };
    baseApi.Invitations.creatFamilyDayRegisteration(registrationModel).then(
      (response: any) => {
        //console.log(response);
        if (response) {
       
          handleNext();
        } 
      });
  }

  return (
    <>
      <List disablePadding>
        <ListItem>
          <ListItemText
            primary="User Id"
            secondary={userId.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="User Name"
            secondary={userName.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Email"
            secondary={email.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Afdeling"
            secondary={familyDayDepartment.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Antal voksne"
            secondary={familyDayAdults.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Antal børn (under 12)"
            secondary={familyDayChildsUnder12.value || "Not Provided"}
          />
        </ListItem>
      </List>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="default" onClick={handleBack}>
          Back
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Save & Continue
        </Button>
      </div>
    </>
  );
}
