import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from "react-i18next";

export const SignInButton = () => {
    const { instance } = useMsal();
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogin = (loginType: string) => {
        setAnchorEl(null);

        if (loginType === "popup") {
            instance.loginPopup();
        } else if (loginType === "redirect") {
            instance.loginRedirect();
        }
    }

    return (
        <div>
            <Button
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
            >
                {t("login_btn_caption")}
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {/* <MenuItem onClick={() => handleLogin("popup")} key="loginPopup">Sign in using Popup</MenuItem> */}
                <MenuItem onClick={() => handleLogin("redirect")} key="loginRedirect">{t("login_btn_caption")}</MenuItem>
            </Menu>
        </div>
    )
};