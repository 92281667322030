import React, { useCallback, useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { AppContext } from '../Context'
import { useAccount, useMsal } from '@azure/msal-react'
import { UserProfile } from '../../_models/common/profile.model'
import { callMsGraph } from '../../_utils/MsGraphApiCall';


export default function FirstStep() {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userProfile, setUserProfile] = useState<null | UserProfile>(null);
  const [onPremisesSamAccountName, setOnPremisesSamAccountName] = useState('');
  const [mail,setmail] = useState('');
  const { formValues, handleChange, handleNext, variant, margin } = useContext(AppContext);
  const { userId, userName,userEmail,adults } = formValues;

  useEffect(() => {
    
     // console.log(account)
     fetchUserProfile();

  }, [account]);

  async function fetchUserProfile() {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            
              callMsGraph(response.accessToken).then((response) => {
              const profile: UserProfile = response as UserProfile;
              if (profile !== null || profile !== undefined) {
               // console.log(profile);

                setUserProfile(profile);
                setOnPremisesSamAccountName(profile.onPremisesSamAccountName);
                setmail(profile.mail);
                userId.value = profile.onPremisesSamAccountName;
                userName.value = profile.displayName;
                userEmail.value = profile.mail;
               // console.log(profile.onPremisesSamAccountName);
                  
                
              }
            });
          }
        });
    }
  }

  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({userId,userName,userEmail,adults }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues,userId,userName,userEmail,adults]
  );



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="User ID"
            name="userId"
            placeholder="Enter User Id"
            value={onPremisesSamAccountName}
            onChange={handleChange}
            error={!!userId.error}
            helperText={userId.error}
            required={userId.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Name"
            name="userName"
            placeholder="Enter Name"
            value={account?.name}
            onChange={handleChange}
            error={!!userName.error}
            helperText={userName.error}
            required={userName.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            variant={variant}
            margin={margin}
            fullWidth
            label="Email"
            name="userEmail"
            placeholder="enter email"
            value={mail}
            onChange={handleChange}
            error={!!userEmail.error}
            helperText={userEmail.error}
            required={userEmail.required}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Adults"
            name="adults"
            placeholder="Enter Number of Adults"
            value={adults.value}
            onChange={handleChange}
            error={!!adults.error}
            helperText={adults.error}
            required={adults.required}
            
          />
        </Grid> */}
      </Grid>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </div>
    </>
  );
}
