import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button } from '@material-ui/core';
import { AppContext } from '../Context';
import baseApi from '../../_api/base.api';
import Loader from '../common/loader/loader';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: theme.spacing(3)
    },
    title: {
        marginTop: 30
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}))

interface Props {
    userProfileData: any
}

export default function AnnualGraduationIntro({ userProfileData }: Props) {

    const classes = useStyles();
    const { formValues, handleChange, handleNext, variant, margin } = useContext(AppContext);
    const [userRole, setUserRole] = useState();
    //console.log(userProfileData);

    useEffect(() => {
        //fetchUserInfo("287228");
        fetchUserInfo(userProfileData.onPremisesSamAccountName);

    }, []);

    const fetchUserInfo = async (userId: string) => {

        baseApi.Invitations.GetAnnualGraduationPartyUser(userId).then(
            (response: any) => {
                if (response) {
                    if (response !== null) {
                        //console.log(response);
                        setUserRole(response?.role ? response?.role : '');


                    }
                }
            });
    }
    if (userRole === "Student") {
        return (
            <>
                <div className={classes.box}>

                    {/* <Typography variant="h6" align="center">
                        {userRole}
                    </Typography> */}
                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>Salling Group inviterer til Danmarks største dimissionsfest 2023</p>
                    <p>Du inviteres hermed til et brag af en dimissionsfest, når vi hylder alle vores elever på tværs af hele Salling Group organisationen.
                        Alle elever fra Bilka, Netto, føtex, Salling, BR, HQ og lagre, der er eller bliver udlært i perioden 1. marts 2023 til 28.
                        februar 2024 – er inviteret. Du skal ikke bruge en fridag og du er velkommen til at tage en ledsager med (mor, far, kæreste eller lign.).
                        Vi har også inviteret din nærmeste leder under din uddannelsestid. </p>
                    <p>Vi glæder os meget til at se jer og hylde jeres store indsats: I er vigtige for Salling Groups fremtid. I bidrager hver dag med entusiasme, viden,
                        hårdt arbejde og en lyst til både at udfordre og forbedre vores forretning. TAK til jer alle. I fortjener at blive fejret.
                        Det gør vi den 21. september 2023 kl. 11.00 – 15.30 i Fredericia med et både festligt og spændende program,
                        som I kommer til at høre meget mere om.</p>
                    <p>Klik på ”Næste” for at tilmelde dig. </p>
                </div>

                <div
                    style={{
                        display: "flex",
                        marginTop: 50,
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        Næste
                    </Button>
                </div>
            </>
        )
    }
    else if (userRole === "Manager") {
        return (
            <>
                <div className={classes.box}>
                    {/* <Typography variant="h6" align="center">
                        {userRole}
                    </Typography> */}
                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>Salling Group holder Danmarks største dimissionsfest den 21. september 2023</p>
                    <p>Som leder inviteres du hermed til et brag af en dimissionsfest, når vi hylder alle vores elever på tværs af hele Salling Group organisationen. Alle elever fra Bilka, Netto, føtex, Salling, BR, HQ og lagre, der er eller bliver udlært i perioden 1. marts 2023 til 28. februar 2024 – er inviteret. </p>
                    <p>Vi glæder os meget til at hylde alle vores dygtige udlærte elever og jer ledere, der har ydet en meget stor indsats for at give dem Danmarks bedste uddannelse i detail-branchen. </p>
                    <p>Vores elever er vigtige for Salling Group – både i det daglige arbejde og som fremtidens medarbejdere. De bidrager hver dag med entusiasme, viden, hårdt arbejde og en lyst til både at udfordre og forbedre vores forretning. De fortjener at blive fejret. Det gør vi den 21. september 2023 kl. 11.00 – 15.30 i Fredericia med et både festligt og spændende program, som I kommer til at høre meget mere om. </p>
                    <p>Klik på ”Næste” for at tilmelde dig.</p>

                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: 50,
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        Næste
                    </Button>
                </div>
            </>
        )

    }
    else {
        return (
            <>
                <div className={classes.box}>

                </div>
            </>
        )

    }
}
