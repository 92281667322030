import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { StepsProvider } from '../Context';
import FamilyDayEventsStepForm from './FamilyDayEventsStepForm';
import CloseInvitation from '../CloseInvitation';
import Loading from '../Loading';
import { DateTime } from "luxon";
import baseApi from '../../_api/base.api';
import { UserProfile } from '../../_models/common/profile.model';
import { Grid, Typography } from '@material-ui/core';
import Familiedagcover from '../Familiedagcover.jpg'
const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3)
    }
  },

}))

export interface FamilyDayEventsParticipants {
  eventName: string;
  totalCount: number;
}

export default function FamilyDayEventsComponent() {

  const classes = useStyles();
  const [isExpire, setIsExpire] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [loadEventsForm, setLoadEventsForm] = useState(false);

  useEffect(() => {

    const profile = localStorage.getItem("UserProfile");
    let userProfile: UserProfile = {} as UserProfile;
    userProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

    console.log(userProfile.onPremisesSamAccountName);

    function fetchEventsRegistration(userId: string) {

      baseApi.Invitations.GetFamilyDayEventsParticipantsByUser(userId).then(
        (response: FamilyDayEventsParticipants[]) => {
          if (response) {
            console.log(response)
            if (response !== null && response !== undefined) {
              let noRegisteration = response.find(x => x.eventName == "No Registeration Found");
              if (noRegisteration?.totalCount == 0) {
                setLoadEventsForm(true);
              }
              else {
                setLoadEventsForm(false);
              }

            }

          }
        });
    }
    function fetchFamilyDayEventsParticipants() {
      baseApi.Invitations.GetFamilyDayEventsParticipants().then(
        (response: FamilyDayEventsParticipants[]) => {
          if (response) {
            let totalCount = 0;
            if (response != null && response != undefined) {

              let perBankEvent = response.find(x => x.eventName == "Per Bank");
              if (perBankEvent !== null && perBankEvent !== undefined && perBankEvent.totalCount !== 0) {
                totalCount += perBankEvent.totalCount;
              }

              let foredrag1Event = response.find(x => x.eventName == "Foredrag 1");
              if (foredrag1Event !== null && foredrag1Event !== undefined && foredrag1Event.totalCount !== 0) {
                totalCount += foredrag1Event.totalCount;
              }
              let foredrag2Event = response.find(x => x.eventName == "Foredrag 2");
              if (foredrag2Event !== null && foredrag2Event !== undefined && foredrag2Event.totalCount !== 0) {
                totalCount += foredrag2Event.totalCount;
              }
              let foredrag3Event = response.find(x => x.eventName == "Foredrag 3");
              if (foredrag3Event !== null && foredrag3Event !== undefined && foredrag3Event.totalCount !== 0) {
                totalCount += foredrag3Event.totalCount;
              }
              let foredrag4Event = response.find(x => x.eventName == "Foredrag 4");
              if (foredrag4Event !== null && foredrag4Event !== undefined && foredrag4Event.totalCount !== 0) {
                totalCount += foredrag4Event.totalCount;
              }
              let foredrag5Event = response.find(x => x.eventName == "Foredrag 5");
              if (foredrag5Event !== null && foredrag5Event !== undefined && foredrag5Event.totalCount !== 0) {
                totalCount += foredrag5Event.totalCount;
              }
              let foredrag6Event = response.find(x => x.eventName == "Foredrag 6");
              if (foredrag6Event !== null && foredrag6Event !== undefined && foredrag6Event.totalCount !== 0) {
                totalCount += foredrag6Event.totalCount;
              }
              let foredrag7Event = response.find(x => x.eventName == "Foredrag 7");
              if (foredrag7Event !== null && foredrag7Event !== undefined && foredrag7Event.totalCount !== 0) {
                totalCount += foredrag7Event.totalCount;
              }
              let foredrag8Event = response.find(x => x.eventName == "Foredrag 8");
              if (foredrag8Event != null && foredrag8Event != undefined && foredrag8Event.totalCount !== 0) {
                totalCount += foredrag8Event.totalCount;
              }
              let foredrag9Event = response.find(x => x.eventName == "Foredrag 9");
              if (foredrag9Event != null && foredrag9Event != undefined && foredrag9Event.totalCount !== 0) {
                totalCount += foredrag9Event.totalCount;
              }
              let foredrag10Event = response.find(x => x.eventName == "Foredrag 10");
              if (foredrag10Event != null && foredrag10Event != undefined && foredrag10Event.totalCount !== 0) {
                totalCount += foredrag10Event.totalCount;
              }
              let foredrag11Event = response.find(x => x.eventName == "Foredrag 11");
              if (foredrag11Event != null && foredrag11Event != undefined && foredrag11Event.totalCount !== 0) {
                totalCount += foredrag11Event.totalCount;
              }
              let foredrag12Event = response.find(x => x.eventName == "Foredrag 12");
              if (foredrag12Event != null && foredrag12Event != undefined && foredrag12Event.totalCount !== 0) {
                totalCount += foredrag12Event.totalCount;
              }
              let foredrag13Event = response.find(x => x.eventName == "Foredrag 13");
              if (foredrag13Event != null && foredrag13Event != undefined && foredrag13Event.totalCount !== 0) {
                totalCount += foredrag13Event.totalCount;
              }
              // let foredrag14Event = response.find(x => x.eventName == "Foredrag 14");
              // if (foredrag14Event != null && foredrag14Event != undefined) {
              //   if (foredrag14Event.totalCount >= 95) {
              //     familyDayForedrag14Adults.value = "Optaget";
              //     setForedrag14Disabled(true);
              //   }
              // }
              // let foredrag15Event = response.find(x => x.eventName == "Foredrag 15");
              // if (foredrag15Event != null && foredrag15Event != undefined) {
              //   if (foredrag15Event.totalCount >= 55) {
              //     familyDayForedrag15Adults.value = "Optaget";
              //     setForedrag15Disabled(true);
              //   }
              // }
              let lagerrundtur1 = response.find(x => x.eventName == "Lagerrundtur 1");
              if (lagerrundtur1 != null && lagerrundtur1 != undefined && lagerrundtur1.totalCount !== 0) {
                totalCount += lagerrundtur1.totalCount;
              }
              let lagerrundtur2 = response.find(x => x.eventName == "Lagerrundtur 2");
              if (lagerrundtur2 != null && lagerrundtur2 != undefined && lagerrundtur2.totalCount !== 0) {
                totalCount += lagerrundtur2.totalCount;
              }
              let lagerrundtur3 = response.find(x => x.eventName == "Lagerrundtur 3");
              if (lagerrundtur3 != null && lagerrundtur3 != undefined && lagerrundtur3.totalCount !== 0) {
                totalCount += lagerrundtur3.totalCount;
              }
              let lagerrundtur4 = response.find(x => x.eventName == "Lagerrundtur 4");
              if (lagerrundtur4 != null && lagerrundtur4 != undefined && lagerrundtur4.totalCount !== 0) {
                totalCount += lagerrundtur4.totalCount;
              }
              let lagerrundtur5 = response.find(x => x.eventName == "Lagerrundtur 5");
              if (lagerrundtur5 != null && lagerrundtur5 != undefined && lagerrundtur5.totalCount !== 0) {
                totalCount += lagerrundtur5.totalCount;
              }
              let lagerrundtur6 = response.find(x => x.eventName == "Lagerrundtur 6");
              if (lagerrundtur6 != null && lagerrundtur6 != undefined && lagerrundtur6.totalCount !== 0) {
                totalCount += lagerrundtur6.totalCount;
              }


              console.log(totalCount)
              setTotalParticipants(totalCount);

            }
            //console.log(response)
          }
        });
    }

    getExpiryTimes("FamilyDaySignUp");
    fetchEventsRegistration(userProfile.onPremisesSamAccountName);
    fetchFamilyDayEventsParticipants();

  }, []);

  const getExpiryTimes = async (pageName: string) => {
    baseApi.Invitations.GetExpiryTimes(pageName).then((response: any) => {
      if (response) {

        var expiry = response.expiry;
        var currDateTime = DateTime.fromISO(DateTime.now().toISO());
        var expireDate = DateTime.fromISO(expiry);
        if (expireDate >= currDateTime) {
          setIsExpire(false);
        }
        setIsLoading(false)
      }
    });
  }

  if (isExpire && isLoading == false) {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img src={Familiedagcover} alt="" style={{ maxWidth: "100%", height: "auto" }}  ></img>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <CloseInvitation />
          </Paper>
        </main>
      </>
    );
  }
  else if ((!isExpire && isLoading == false) && (totalParticipants > 2000 && loadEventsForm === true)) {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img src={Familiedagcover} alt="" style={{ maxWidth: "100%", height: "auto" }}  ></img>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Typography variant="h6" align="center">
              <div>Det er desværre ikke længere muligt at tilmelde sig familiedagen da alle pladser er optage</div>
            </Typography>
          </Paper>
        </main>

      </>
    );
  }
  else if ((!isExpire && isLoading == false) && (totalParticipants <= 2000 && loadEventsForm === true)) {
    return (
      <StepsProvider>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* <FamilyDayStepForm /> */}
            <FamilyDayEventsStepForm />
          </Paper>
        </main>
      </StepsProvider>
    )
  }
  else if ((!isExpire && isLoading == false) && loadEventsForm === false) {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img src={Familiedagcover} alt="" style={{ maxWidth: "100%", height: "auto" }}  ></img>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Typography variant="h6" align="center">
              <div>Du er allerede tilmeldt HQ Familiedag</div>
            </Typography>
          </Paper>
        </main>
      </>
    )
  }
  else {
    return (
      <>
        <CssBaseline />
        <main className={classes.layout}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img src={Familiedagcover} alt="" style={{ maxWidth: "100%", height: "auto" }}  ></img>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Loading />
          </Paper>
        </main>

      </>
    );
  }
}
