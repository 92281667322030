import React, { useCallback, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
//import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../Context'
//import { List, Divider, ListItem, ListItemText, Fab } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'



export default function SMC_GeneralComments() {

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin,
    listChildren,
    addChildren
  } = useContext(AppContext);


  const {userId,userName,userEmail,gender,telephone,date,adults,childrenCount,employeeTShirtSize,firstParentName,firstParentGender,firstParentBirthday,firstParentTShirtSize,secondParentName,secondParentGender,secondParentBirthday,secondParentTShirtSize,child1Name,child1Gender,child1Birthday,child1TShirtSize,child2Name,child2Gender,child2Birthday,child2TShirtSize,child3Name,child3Gender,child3Birthday,child3TShirtSize,child4Name,child4Gender,child4Birthday,child4TShirtSize,child5Name,child5Gender,child5Birthday,child5TShirtSize,transportRequired,driveYourself,taxCoverage,cateringComments,generalComments} = formValues;

  const isError = useCallback(
    () =>
      Object.keys({userId,userName,userEmail,gender,telephone,date,adults,childrenCount,employeeTShirtSize,firstParentName,firstParentGender,firstParentBirthday,firstParentTShirtSize,secondParentName,secondParentGender,secondParentBirthday,secondParentTShirtSize,child1Name,child1Gender,child1Birthday,child1TShirtSize,child2Name,child2Gender,child2Birthday,child2TShirtSize,child3Name,child3Gender,child3Birthday,child3TShirtSize,child4Name,child4Gender,child4Birthday,child4TShirtSize,child5Name,child5Gender,child5Birthday,child5TShirtSize,transportRequired,driveYourself,taxCoverage,cateringComments,generalComments}).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues,userId,userName,userEmail,gender,telephone,date,adults,childrenCount,employeeTShirtSize,firstParentName,firstParentGender,firstParentBirthday,firstParentTShirtSize,secondParentName,secondParentGender,secondParentBirthday,secondParentTShirtSize,child1Name,child1Gender,child1Birthday,child1TShirtSize,child2Name,child2Gender,child2Birthday,child2TShirtSize,child3Name,child3Gender,child3Birthday,child3TShirtSize,child4Name,child4Gender,child4Birthday,child4TShirtSize,child5Name,child5Gender,child5Birthday,child5TShirtSize,transportRequired,driveYourself,taxCoverage,cateringComments,generalComments]
  );

  const handleSubmit = () => {

    // let request: InvitationRequestModel = {
    //   id: 0,
    //   userId: userId.value,
    //   userName: userName.value,
    //   email: userEmail.value,
    //   adults: Number(adults.value),
    //   zeroToOne: Number(zeroToOne.value),
    //   twoYear: Number(twoYear.value),
    //   threeToFiveDreng: Number(threeToFiveDreng.value),
    //   threeToFivePige: Number(threeToFivePige.value),
    //   sixToEightDreng: Number(sixToEightDreng.value),
    //   sixToEightPige: Number(sixToEightPige.value),
    //   nineToTwelveDreng: Number(nineToTwelveDreng.value),
    //   nineToTwelvePige: Number(nineToTwelvePige.value),
    // };


    // baseApi.Invitations.create(request).then(
    //   (response: any) => {
    //     //console.log(response);
    //     if (response) {
    //       adults.value = ''
    //       handleNext();
    //     } 
    //   });
 
    
    // Show last component or success message
   // handleNext();
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
             If you have comments, write them here:
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
            <Grid item xs={12}>
            <TextField
                type="text"
                variant={variant}
                margin={margin}
                fullWidth
                label="Comments"
                name="generalComments"
                placeholder=""
                value={generalComments.value}
                defaultValue={generalComments.value}
                onChange={handleChange}
                error={!!generalComments.error}
                helperText={generalComments.error}
                required={generalComments.required}
            />
            </Grid>
      
        </Grid>
     
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" disabled={isError()} onClick={!isError() ? handleSubmit : () => null} >
          Next
        </Button>
      </div>
    </>
  );
}
