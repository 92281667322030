import { useState } from "react";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import HashLoader from "react-spinners/HashLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
display: block;

`;

interface props {
    loading:boolean
}
export default function Loader ({loading}:props) {

    let [color, setColor] = useState("#004a62");

    return (
      <div className="spinnerStyle">
        <ScaleLoader
         css={override}
          color={color}
          loading={loading}
          height={35}
          width={8}
          radius={2}
          margin={2}
        />
        {/* <HashLoader size={50}/> */}
      </div>
    
    );
}
