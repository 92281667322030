import React, { useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import { Grid } from '@material-ui/core'
import { AppContext } from '../Context';


const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3)
  },
  title: {
    marginTop: 30
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }
}))

export default function SommerCampSuccess() {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const { handleDefaultStep } = useContext(AppContext);
  const handleOnClick = () => {
    //set the redirect property to true for allow redirect
    //setRedirect(true);
    handleDefaultStep();
  }
  return (
    <>
      <div className={classes.box}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Thank you for your registration, you will receive an email from us in week 6 if you have been given a place at Bøgehøj SommerCamp 2022.
            </Typography>
          </Grid>
          <Grid item xs={12}>
           
          </Grid>
        </Grid>
      </div>
      {/* {redirect === true ? (
      <Redirect push to="/Invitation" />
      
    ) : (
    <div className={classes.box}>
      <Typography variant="h6" align="center">
        Thank you, Information has been saved successfully!
      </Typography>
      <Typography component="p" align="center" className={classes.title}>
      <Fab variant="extended" onClick={handleOnClick}>
        <AddIcon  className={classes.extendedIcon}  />
            Add more? Click here!
      </Fab>
      </Typography>  
    </div>
      )} */}
    </>
  );
}
