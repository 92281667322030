import React, { useCallback, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
//import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../Context'
//import { List, Divider, ListItem, ListItemText, Fab } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function SMC_ParentInfoPage() {

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin,
    listChildren,
    addChildren
  } = useContext(AppContext);
  const [firstParentDate, setFirstParentDate] = React.useState<Date | null>();
  const [secondParentDate, setSecondParentDate] = React.useState<Date | null>();

  const {firstParentName,firstParentGender,firstParentBirthday,firstParentTShirtSize,secondParentName,secondParentGender,secondParentBirthday,secondParentTShirtSize } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({firstParentName,firstParentGender,firstParentBirthday,firstParentTShirtSize,secondParentName,secondParentGender,secondParentBirthday,secondParentTShirtSize}).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues,firstParentName,firstParentGender,firstParentBirthday,firstParentTShirtSize,secondParentName,secondParentGender,secondParentBirthday,secondParentTShirtSize]
  );

  const handleFirstParentBirthdayChange = (vDate: Date | null) => {
    setFirstParentDate(vDate);
    firstParentBirthday.value = vDate?.toISOString();
  };

  const handleSecondParentBirthdayChange = (vDate: Date | null) => {
    setSecondParentDate(vDate);
    secondParentBirthday.value = vDate?.toISOString();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Parent No. 1
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="firstParentName"
            placeholder=""
            value={firstParentName.value}
            defaultValue={firstParentName.value}
            onChange={handleChange}
            error={!!firstParentName.error}
            helperText={firstParentName.error}
            required={firstParentName.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="firstParentGender"
            value={firstParentGender.value}
            onChange={handleChange}
            error={!!firstParentGender.error}
            helperText={firstParentGender.error}
            required={firstParentGender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="firstParentTShirtSize"
            value={firstParentTShirtSize.value}
            onChange={handleChange}
            error={!!firstParentTShirtSize.error}
            helperText={firstParentTShirtSize.error}
            required={firstParentTShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="firstParentDate"
              label="Birthday"
              format="MM/dd/yyyy"
              value={firstParentDate}
              onChange={handleFirstParentBirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Parent No. 2
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="secondParentName"
            placeholder=""
            value={secondParentName.value}
            defaultValue={secondParentName.value}
            onChange={handleChange}
            error={!!secondParentName.error}
            helperText={secondParentName.error}
            required={secondParentName.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="secondParentGender"
            value={secondParentGender.value}
            onChange={handleChange}
            error={!!secondParentGender.error}
            helperText={secondParentGender.error}
            required={secondParentGender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="secondParentTShirtSize"
            value={secondParentTShirtSize.value}
            onChange={handleChange}
            error={!!secondParentTShirtSize.error}
            helperText={secondParentTShirtSize.error}
            required={secondParentTShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="secondParentDate"
              label="Birthday"
              format="MM/dd/yyyy"
              value={secondParentDate}
              onChange={handleSecondParentBirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" disabled={isError()} onClick={!isError() ? handleNext : () => null} >
          Next
        </Button>
      </div>
    </>
  );
}
