import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { useAlert } from "react-alert";
import { AppContext } from '../Context'


const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: theme.spacing(3)
    },
    title: {
        marginTop: 30
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },

}))

interface Props {
    userProfileData: any
}


export default function AnnualGraduationAcknowledgment({ userProfileData }: Props) {

    const classes = useStyles();
    const alert = useAlert();
    const [legalStatmentAcknowledgment, setLegalStatmentAcknowledgment] = useState(false);
    const { formValues, handleChange, handleNext, handleBack, variant, margin } = useContext(AppContext);
    const { userId, userName, agp_legalAcknowledgment } = formValues;


    useEffect(() => {

        if (agp_legalAcknowledgment.value === "Ja") {
            setLegalStatmentAcknowledgment(true);

        }
        else {
            setLegalStatmentAcknowledgment(false)
        }

        //agp_legalAcknowledgment.value = legalStatmentAcknowledgment ? "Ja" : "Nej";



    }, []);


    const handleTransportChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setLegalStatmentAcknowledgment(event.target.checked);

        agp_legalAcknowledgment.value = event.target.checked ? "Ja" : "Nej";
    };

    const handelNextHandler = () => {

        if (legalStatmentAcknowledgment) {
            agp_legalAcknowledgment.value = "Ja";
            handleNext();
        }
        else {
            alert.error('Det er ikke muligt at tilmelde sig, uden at godkende betingelserne');
        }

    }


    return (
        <>

            <div className={classes.box}>
                <div className="home-container">
                    <h2 className="home-text">
                        Generel info om og privatlivspolitik for Salling Groups Dimissionsfest
                        2023
                    </h2>
                    <span className="home-text01">
                        Kære deltager ved Salling Groups Dimissionsfest 2023.
                    </span>
                    <span className="home-text02">
                        Du kan læse mere om behandlingen af dine personoplysninger i forbindelse
                        med Dimissionsfesten 2023 i denne privatlivspolitik. Du er altid
                        velkommen til at kontakte os via
                        https://hrgdpronly.sallinggroup.com/hc/da hvis du har spørgsmål til
                        vores behandling af dine personoplysninger.
                    </span>
                    <span className="home-text03">
                        <span className="home-text04">Bemærk,</span>
                        <span>
                            {' '}
                            at der vil blive taget fotos og video i forbindelse med
                            Dimissionsfesten 2023. Billederne vil blive anvendt i interne og
                            eksterne sammenhænge. Hvis du deltager til dimissionsfesten skal du
                            derfor være opmærksom på, at dette kan indebære, at billeder eller
                            videoer hvor du fremgår, bliver offentliggjort.
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: ' ',
                                }}
                            />
                        </span>
                    </span>
                    <span className="home-text06">
                        Vi gennemgår de udvalgte fotos, inden de gøres tilgængelige. Det gør vi
                        for at sikre, at der ikke er billeder, som kan virke anstødelige eller
                        kan føles krænkende for den person, som er på billedet eller videoen.
                    </span>
                    <span className="home-text07">
                        Du kan læse mere herom under afsnittet ”privatlivspolitik” nedenfor.
                    </span>
                    <span className="home-text08">
                        Betingelser for deltagelse i Dimissionsfesten 2023:
                    </span>
                    <span className="home-text09">
                        For at deltage i Dimissionsfesten 2023 skal medarbejderen være udlært
                        elev i Salling Group i perioden 1. marts 2023 til 28. februar 2024. Der
                        mulighed for at invitere en ledsager med.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text10">
                        Som deltager skal man selv bekræfte sin deltagelse + evt. ledsager via
                        tilmeldingslinket.
                    </span>
                    <span className="home-text11">
                        Privatlivspolitik
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text12">
                        Sådan behandler vi dine personoplysninger i forbindelse med
                        Dimissionsfesten 2023:
                    </span>
                    <span className="home-text13">
                        Salling Group er ansvarlig for behandlingen af dine personoplysninger.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text14">Salling Group A/S</span>
                    <span className="home-text15">CVR-nr. 35954716</span>
                    <span className="home-text16">Rosbjergvej 33</span>
                    <span className="home-text17">8220 Brabrand</span>
                    <span className="home-text18">
                        Du er altid velkommen til at kontakte os, hvis du har spørgsmål til
                        behandlingen af dine personoplysninger, via
                        https://hrgdpronly.sallinggroup.com/hc/da.
                    </span>
                    <span className="home-text19">
                        Formål og retsgrundlag for behandling af dine personoplysninger
                    </span>
                    <span className="home-text20">
                        Vi behandler dine personoplysninger til følgende formål:
                    </span>
                    <ul className="list">
                        <li className="list-item">
                            <span className="home-text21">
                                Administration af din tilmelding for at du kan deltage i
                                Dimissionsfesten 2023
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text22">
                                Udsendelse af e-mails med oplysninger om Dimissionsfesten 2023 for
                                at informere dig om eventet
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text23">
                                Optagelse og deling af fotografier og video fra eventet til intern
                                og eksternt brug som led i branding og markedsføring af Salling
                                Group A/S som arbejdsplads, samt for at give et indblik i events og
                                aktiviteter hos os.
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: ' ',
                                    }}
                                />
                            </span>
                        </li>
                    </ul>
                    <span className="home-text24">
                        Retsgrundlaget for vores behandling af dine personoplysninger følger af:
                    </span>
                    <ul className="list">
                        <li className="list-item">
                            <span className="home-text25">
                                Dit samtykke til at vi behandler dine personoplysninger i
                                forbindelse med tilmelding til og
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: ' ',
                                    }}
                                />
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text26">
                                At vi har en legitim interesse i at udsende e-mails med
                                information om eventet til dig, jf. databeskyttelsesforordningens
                                artikel 6(1)(f)
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text27">
                                Vores legitime interesse i at offentliggøre billeder og videoer
                                både internt og eksternt fra arrangementer og aktiviteter for at
                                brande og markedsføre Salling Group A/S som arbejdsplads, jf.
                                databeskyttelsesforordningens artikel 6(1)(f)
                            </span>
                        </li>
                    </ul>
                    <span className="home-text28">Kategorier af personoplysninger</span>
                    <span className="home-text29">
                        Vi behandler følgende kategorier af personoplysninger om dig:
                    </span>
                    <ul className="list">
                        <li className="list-item">
                            <span className="home-text30">
                                <span>Almindelige personoplysninger</span>
                                <br></br>
                                <ul className="list">
                                    <li className="list-item">Navn, e-mail, SAP ID</li>
                                    <li className="list-item">Butiksnummer/afdeling</li>
                                    <li className="list-item">Fotos og videooptagelser fra eventet</li>
                                </ul>
                            </span>
                        </li>

                    </ul>
                    <span className="home-text39">
                        Modtagere eller kategorier af modtagere
                    </span>
                    <span className="home-text40">
                        Vi kan efter omstændighederne overlade eller videregive dine
                        personoplysninger til følgende kategorier af modtagere som led i
                        opfyldelsen af ovenstående formål:
                    </span>
                    <ul className="list">
                        <li className="list-item">
                            <span className="home-text41">
                                Databehandlere, som leverer services til den databehandling, der
                                muliggør eventet. Det kan f.eks. være leverandører af
                                softwareløsninger, der hoster en tilmeldingsløsning eller
                                leverandører der bistår med at udsende relevante e-mails.
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text42">
                                Databehandlere, som leverer services til deling af fotografier og
                                video internt.
                            </span>
                        </li>

                    </ul>
                    <span className="home-text43">Overførsel til lande uden for EU/EØS</span>
                    <span className="home-text44">
                        Salling Group anvender databehandlere, der i forbindelse med bl.a.
                        hosting af services anvender underdatabehandlere, der overfører
                        personoplysninger til usikre tredjelande.
                    </span>
                    <span className="home-text45">
                        Salling Group sikrer, at overførslerne sker efter reglerne i GDPRs
                        kapitel 5. Nærmere bestemt sikrer Salling Group, at der er indgået de
                        tilstrækkelige Standardkontraktsbestemmelser (EU SCC), jf. GDPRs artikel
                        46(2)(c).
                    </span>
                    <span className="home-text46">Fotos og video</span>
                    <span className="home-text47">
                        Der vil blive taget fotos og optaget video fra eventet til intern og
                        ekstern brug i Salling Group, som vil blive benyttet til branding og
                        markedsføring af Salling Group A/S som arbejdsplads, samt for at give et
                        indblik i events og aktiviteter hos os.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text48">
                        Hvis du deltager i dimissionsfesten skal du derfor være opmærksom på, at
                        det kan betyde, du også fremgår af billeder eller videoer optaget til
                        eventet.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text49">
                        Udvalgte billeder og videoklip vil efter eventet blive gjort tilgængelig
                        på forskellige platforme. Eksternt materiale slettes efter 12 måneder,
                        mens internt materiale slettes, når det ikke længere er relevant.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text50">
                        Vi gennemgår de udvalgte fotos, inden de gøres tilgængelige. Det gør vi
                        for at sikre, at der ikke er billeder, som kan virke anstødelige eller
                        kan føles krænkende for den person, som er på billedet eller videoen.
                    </span>
                    <span className="home-text51">
                        Du har ret til at gøre indsigelse mod offentliggørelsen af billed- eller
                        videomateriale fra eventet med dig på, hvis du ønsker billedet fjernet.
                        Vi vil herefter tage stilling til, om og i hvilket omfang materialet kan
                        fjernes i overensstemmelse med GDPRs artikel 21.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text52">
                        Du kan indgive en indsigelse her hvis du er medarbejder og her hvis du
                        er ledsager til en medarbejder.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text53">
                        Retten til at trække samtykke tilbage
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text54">
                        Du har til enhver tid ret til at trække dit samtykke tilbage. Det kan du
                        gøre her hvis du er medarbejder og her hvis du er ledsager til en
                        medarbejder.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text55">
                        Hvis du vælger at trække dit samtykke tilbage, påvirker det ikke
                        lovligheden af vores behandling af dine personoplysninger på baggrund af
                        det samtykke, du tidligere har givet, og op til tidspunktet for
                        tilbagetrækningen. Hvis du tilbagetrækker dit samtykke, har det derfor
                        først virkning fra dette tidspunkt.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text56">
                        Vær opmærksom på, at hvis du vælger at trække dit samtykke tilbage i
                        forhold til oplysninger afgivet til os, vil vi ikke være i stand til at
                        viderebehandle dine oplysninger, medmindre vi er retligt eller
                        kontraktuelt forpligtet hertil.
                    </span>
                    <span className="home-text57">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text58">
                        Bemærk, at hvis du trækker dit samtykke til behandlingen af dine
                        tilmeldingsoplysninger tilbage, har du ikke længere mulighed for at
                        deltage i Dimissionsfesten 2023.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text59">
                        Hvor stammer dine personoplysninger fra
                    </span>
                    <span className="home-text60">
                        De personoplysninger, som vi behandler om dig i forbindelse med eventet
                        stammer fra:
                    </span>
                    <ul className="list">
                        <li className="list-item">
                            <span className="home-text61">
                                De oplysninger du har givet i forbindelse med tilmelding til
                                eventet
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: ' ',
                                    }}
                                />
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text62">
                                Oplysninger der allerede er lagret i Salling Groups systemer
                                f.eks. SAP ID og butiksnummer
                            </span>
                        </li>
                        <li className="list-item">
                            <span className="home-text63">
                                Billeder og videomateriale optaget til event
                            </span>
                        </li>
                    </ul>
                    <span className="home-text64">Opbevaring af dine personoplysninger</span>
                    <span className="home-text65">
                        Vi opbevarer dine personoplysninger, som du indtaster i
                        tilmeldingsløsningen, i 1 år efter eventet er afsluttet. Herefter vil
                        dine oplysninger blive slettet i tilmeldingssystemet.
                    </span>
                    <span className="home-text66">
                        Fotografier og video, der ikke anvendes eksternt, bliver slettet efter
                        12 måneder. Fotografier og video som anvendes internt vil blive fjernet,
                        når materialet ikke længere er relevant.
                    </span>
                    <span className="home-text67">Dine rettigheder</span>
                    <span className="home-text68">
                        Du har efter databeskyttelsesforordningen en række rettigheder i forhold
                        til vores behandling af oplysninger om dig. Vil du gøre brug af dine
                        rettigheder, kan du kontakte os her hvis du er medarbejder og
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text69">
                        Ret til at se oplysninger (indsigtsret)
                    </span>
                    <span className="home-text70">
                        Du har ret til at få indsigt i de oplysninger, som vi behandler om dig,
                        samt en række yderligere oplysninger.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text71">Ret til berigtigelse (rettelse)</span>
                    <span className="home-text72">
                        Du har ret til at få urigtige oplysninger om dig selv rettet.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text73">Ret til sletning</span>
                    <span className="home-text74">
                        Du har ret til at anmode om at få personoplysninger om dig selv slettet
                        uden unødig forsinkelse under visse omstændigheder, eksempelvis hvis
                        personoplysningerne ikke længere er nødvendige til at opfylde de formål,
                        hvortil de blev indsamlet eller på anden vis behandlet.
                    </span>
                    <span className="home-text75">Ret til begrænsning af behandling</span>
                    <span className="home-text76">
                        Du har i visse tilfælde ret til at få behandlingen af dine persondata
                        begrænset. Hvis du har ret til at få begrænset behandlingen, må vi
                        fremover kun behandle oplysningerne – bortset fra opbevaring – med dit
                        samtykke, eller med henblik på at retskrav kan fastlægges, gøres
                        gældende eller forsvares, eller for at beskytte en person eller vigtige
                        samfundsinteresser.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text77">Ret til indsigelse</span>
                    <span className="home-text78">
                        Du har i visse tilfælde ret til at gøre indsigelse mod vores ellers
                        lovlige behandling af dine persondata. Du kan også gøre indsigelse mod
                        behandling af dine oplysninger til direkte markedsføring.
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span className="home-text79">
                        Ret til at transmittere oplysninger (dataportabilitet)
                    </span>
                    <span className="home-text80">
                        Du har i visse tilfælde ret til at modtage dine persondata i et
                        struktureret, almindeligt anvendt og maskinlæsbart format samt at få
                        overført disse persondata fra én dataansvarlig til en anden uden
                        hindring.
                    </span>
                    <span className="home-text81">
                        Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de
                        registreredes rettigheder, som du finder på www.datatilsynet.dk.
                    </span>
                    <span className="home-text82">Klage til Datatilsynet</span>
                    <span className="home-text83">
                        Du har ret til at indgive en klage til Datatilsynet, hvis du er
                        utilfreds med den måde, vi behandler dine personoplysninger på. Du
                        finder Datatilsynet’s kontaktoplysninger på www.datatilsynet.dk.
                    </span>
                </div>

            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: 50,
                    justifyContent: "flex-end",
                }}
            >
                <Grid container spacing={1} >
                    <Grid item xs={8}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={legalStatmentAcknowledgment}
                                            onChange={handleTransportChange}
                                            name="transport"
                                            color="primary"
                                        />
                                    }
                                    value={legalStatmentAcknowledgment ? "Ja" : "Nej"}
                                    label={legalStatmentAcknowledgment ? "Ja" : "Nej"}
                                    labelPlacement={legalStatmentAcknowledgment ? "start" : "end"}
                                />
                            </Grid>
                            <Grid item xs zeroMinWidth style={{ textAlign: 'left', marginTop: '8px' }} >

                                Jeg giver hermed mit samtykke til, at Salling Group A/S må behandle mine personoplysninger for at kunne administrere min tilmelding til dimissionsfesten 2023. Du kan til enhver tid trække dit samtykke tilbage, men du skal være opmærksom på, at dit samtykke er en betingelse for, at vi kan administrere din tilmelding, og du kan deltage i eventet.

                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={handleBack}
                            style={{ marginRight: 10 }}
                        >
                            TILBAGE
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handelNextHandler}
                        >
                            Næste
                        </Button>

                    </Grid>
                </Grid>

            </div>
        </>
    )
}
