import React, { useCallback, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
//import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../Context'
import { InvitationRequestModel } from '../../_models/invitation/Invitation.model'
//import { List, Divider, ListItem, ListItemText, Fab } from '@material-ui/core'
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography'
import baseApi from '../../_api/base.api'


export default function SecondStep() {
    const {
        formValues,
        handleChange,
        handleBack,
        handleNext,
        variant,
        margin,
        listChildren,
        addChildren
    } = useContext(AppContext);

    const { userId, userName, userEmail, adults, zeroToOne, twoYear, threeToFiveDreng, threeToFivePige, sixToEightDreng, sixToEightPige, nineToTwelveDreng, nineToTwelvePige } = formValues;

    const isError = useCallback(
        () =>
            Object.keys({ adults, zeroToOne, twoYear, threeToFiveDreng, threeToFivePige, sixToEightDreng, sixToEightPige, nineToTwelveDreng, nineToTwelvePige }).some(
                (name) =>
                    (formValues[name].required && !formValues[name].value) ||
                    formValues[name].error
            ),
        [formValues, adults, zeroToOne, twoYear, threeToFiveDreng, threeToFivePige, sixToEightDreng, sixToEightPige, nineToTwelveDreng, nineToTwelvePige]
    )

    // const handleChildren=() =>{
    //   //console.log("Add");
    //   let obj:ChildrenModel = {
    //     childrenCount : childrenCount.value,
    //     childrenAgeGroup : childrenAgeGroup.value,
    //     childrenGender : gender.value
    //   };
    //   console.log(obj);
    //   addChildren(obj);

    //   childrenCount.value = '';
    //   childrenAgeGroup.value = '';
    //   gender.value = '';
    // }

    const handleSubmit = () => {

        let request: InvitationRequestModel = {
            id: 0,
            userId: userId.value,
            userName: userName.value,
            email: userEmail.value,
            adults: Number(adults.value),
            zeroToOne: Number(zeroToOne.value),
            twoYear: Number(twoYear.value),
            threeToFiveDreng: Number(threeToFiveDreng.value),
            threeToFivePige: Number(threeToFivePige.value),
            sixToEightDreng: Number(sixToEightDreng.value),
            sixToEightPige: Number(sixToEightPige.value),
            nineToTwelveDreng: Number(nineToTwelveDreng.value),
            nineToTwelvePige: Number(nineToTwelvePige.value),
        };


        baseApi.Invitations.create(request).then(
            (response: any) => {
                //console.log(response);
                if (response) {
                    adults.value = ''
                    handleNext();
                }
            });


        // Show last component or success message
        // handleNext();
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: "1.20rem", fontWeight: "bold" }}>
                        Number of adults participating (+12 year)
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Adults"
                        name="adults"
                        placeholder=""
                        value={adults.value}
                        defaultValue={adults.value}
                        onChange={handleChange}
                        error={!!adults.error}
                        helperText={adults.error}
                        required={adults.required}
                    />
                </Grid>
                {/* <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Children Age Group"
            name="childrenAgeGroup"
            value={childrenAgeGroup.value}
            onChange={handleChange}
            error={!!childrenAgeGroup.error}
            helperText={childrenAgeGroup.error}
            required={childrenAgeGroup.required}
          >
            <option value=""></option>
            <option value="0-1 Year">{"0-1 Year"}</option>
            <option value="2 Year">{"2 Year"}</option>
            <option value="3-5 Year">{"3-5 Year"}</option>
            <option value="6-8 Year">{"6-8 Year"}</option>
            <option value="9-12 Year">{"9-12 Year"}</option>
          </TextField>
        </Grid> */}
                {/* <Grid item xs={12} sm={6}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="gender"
            value={gender.value}
            onChange={handleChange}
            error={!!gender.error}
            helperText={gender.error}
            required={gender.required}
          >
            <option value=""></option>
            <option value="Boy">{"Boy"} </option>
            <option value="Girl">{"Girl"} </option>
          </TextField>
        </Grid> */}
                {/* <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "flex-end",
            }}
          >
             <Tooltip title="Add more children">
             <span>
              <Fab color="primary" aria-label="add" disabled={isError()} onClick={!isError() ? handleChildren : () => null}>
                <AddIcon />
              </Fab>
             </span>
            </Tooltip>
          </div>
        </Grid> */}
                {/* <Grid item xs={12}>
          <List disablePadding>
            <Divider />
            {listChildren.map((item: ChildrenModel, index: number) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    `${item.childrenCount} ` ||
                    "Not Provided"
                  }
                  secondary={`${item.childrenAgeGroup} ${item.childrenGender}`}
                />
              </ListItem>
            ))}
          </List>
        </Grid> */}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: "1.20rem", fontWeight: "bold" }}>
                        Number of children participating?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="0-1 year"
                        name="zeroToOne"
                        placeholder=""
                        value={zeroToOne.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!zeroToOne.error}
                        helperText={zeroToOne.error}
                        required={zeroToOne.required}
                    />
                </Grid>

            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="2 year"
                        name="twoYear"
                        placeholder=""
                        value={twoYear.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!twoYear.error}
                        helperText={twoYear.error}
                        required={twoYear.required}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="3-5 year (Boy)"
                        name="threeToFiveDreng"
                        placeholder=""
                        value={threeToFiveDreng.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!threeToFiveDreng.error}
                        helperText={threeToFiveDreng.error}
                        required={threeToFiveDreng.required}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="3-5 year (Girl)"
                        name="threeToFivePige"
                        placeholder=""
                        value={threeToFivePige.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!threeToFivePige.error}
                        helperText={threeToFivePige.error}
                        required={threeToFivePige.required}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="6-8 year (Boy)"
                        name="sixToEightDreng"
                        placeholder=""
                        value={sixToEightDreng.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!sixToEightDreng.error}
                        helperText={sixToEightDreng.error}
                        required={sixToEightDreng.required}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="6-8 year (Girl)"
                        name="sixToEightPige"
                        placeholder=""
                        value={sixToEightPige.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!sixToEightPige.error}
                        helperText={sixToEightPige.error}
                        required={sixToEightPige.required}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="9-12 year (Boy)"
                        name="nineToTwelveDreng"
                        placeholder=""
                        value={nineToTwelveDreng.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!nineToTwelveDreng.error}
                        helperText={nineToTwelveDreng.error}
                        required={nineToTwelveDreng.required}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        type="number"
                        variant={variant}
                        margin={margin}
                        label="9-12 year (Girl)"
                        name="nineToTwelvePige"
                        placeholder=""
                        value={nineToTwelvePige.value}
                        defaultValue={0}
                        onChange={handleChange}
                        error={!!nineToTwelvePige.error}
                        helperText={nineToTwelvePige.error}
                        required={nineToTwelvePige.required}
                    />
                </Grid>
            </Grid>
            <div
                style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
            >
                <Button
                    variant="contained"
                    color="default"
                    onClick={handleBack}
                    style={{ marginRight: 10 }}
                >
                    Back
                </Button>
                <Button variant="contained" color="primary" disabled={isError()} onClick={!isError() ? handleSubmit : () => null} >
                    Send
                </Button>
            </div>
        </>
    );
}
