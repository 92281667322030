import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "app_name":" Click&Collect",
          "lbl_TopNavHome":"Home",
          "copy_right": "Copy rights, All rights are reserved.",
          "version_heading": "Version:",
          "version_code":"1.0.0",
          "info_label":"Info",
          "login_btn_caption":"Log On",
          "logoff_btn_caption":"Log Off",
          "available_store_heading": "Available Stores",
          "action_search":"Search Store",
          "header_orders":"Orders",
          "breadcrumb_lbl_home":"Home",
          "breadcrumb_lbl_orders":"Orders",
          "breadcrumb_lbl_order_detail":"Order Details",
          "tab_lbl_pending":"Pending",
          "tab_lbl_ready":"Ready",
          "tab_lbl_cancelled":"Cancelled",
          "tab_lbl_Expired":"Expired",
          "placeholder_input_search_order":"Search Order",
          "lbl_order_no":"Order No.",
          "lbl_created":"Created",
          "lbl_picked":"Picked",
          "lbl_customer":"Customer",
          "lbl_locked_by":"Locked By:",
          "lbl_more_info": "More Info",
          "header_pick_articles":"Pick Articles",
          "lbl_lock_order":"Lock Order",
          "lbl_remove_fee":"Remove Fee",
          "lbl_order_details": "Order Details",
          "lbl_article_lines": "Article Lines",
          "lbl_Mobile":"Mobile",
          "lbl_email":"Email",
          "lbl_expires":"Expires",
          "lbl_status":"Status",
          "lbl_quantity":"Quantity",
          "lbl_ean":"EAN",
          "lbl_price":"Price",
          "lbl_article_no":"Art. No.",
          "lbl_pickup_spot":"Pickup Spot",
          "lbl_quantity_picked":"Quantity Picked",
          "btn_caption_pick":"Pick",
          "btn_caption_edit":"Edit",
          "btn_caption_image":"Image",
          "header_image":"Image",
          "btn_caption_print":"Print",
          "lbl_select_printer":"Select Printer",
          "lbl_select_label_printer":"Label Printer",
          "lbl_select_celeveron_printer":"Package Tower Printer",
          "btn_caption_sold":"Sold out",
          "btn_caption_undo":"Undo",
          "header_pick_article":"Pick Article",
          "stock_level_at_order_time":"Stock level at order time",
          "select_not_selected_txt":"Not Selected",
          "please_select_a_pickup_spot":"Please select a pickup spot!",
          "barcode_or_article_no":"Barcode or article no.",
          "scan_or_enter_the_article_s_barcode":"Enter the article's barcode or article no.",
          "btn_caption_check": "Check",
          "additional_info":"Additional Info",
          "msg_article_picked":"Article picked!",
          "msg_unable_to_pick_article":"Unable to pick article!",
          "msg_error_found_could_not_mark_pick":"Error found, Could not mark pick!",
          "msg_check_required_fields":"Check the required fields!",
          "order_is_locked":"Order is Locked",
          "order_is_unlocked":"Order is UnLocked",
          "msg_could_not_lock_unlock_order":"Could not lock/unlock the order.",
          "msg_could_not_remove_add_fee":"Could not remove/add fee.",
          "could_not_update_the_article_line":"Could not update the article line.",
          "ok_button_label":"OK",
          "lbl_pickup_code":"Pickup Code",
          "lbl_hand_out_order": "Hand Out Order",
          "lbl_picked_by":"Picked By",
          "lbl_handed_out":"Handed Out",
          "lbl_handed_out_by":"Delivered By",
          "lbl_hand_out":"Hand Out",
          "lbl_handout_quantity_confirm_text": "Please confirm that the selected quantity match the quantity of the article you are about to hand out.",
          "lbl_The_article_barcode:":"The article's barcode:",
          "lbl_confirm_handout":"Confirm Hand Out",
          "lbl_Expired":"Expired",
          "lbl_options":"Options",
          "btn_lbl_extend":"EXTEND",
          "btn_lbl_collected":"COLLECTED",
          "btn_lbl_confirm":"CONFIRM",
          "header_add_pickup_spot":"Add Pickup Spot",
          "handout_lbl_isCpuc": "CPUC Goods Delivery"

         
        }
      },
      de: {
        translations: {
          "handout_lbl_isCpuc": "CPUC Vareudlevering",
          "header_add_pickup_spot":"Add Pickup Spot",
          "btn_lbl_confirm":"Bekræft",
          "btn_lbl_extend":"Afhentet",
          "btn_lbl_collected":"Forlæng",
          "lbl_options":"Valgmuligheder",
          "lbl_Expired":"Udløbet",
          "lbl_confirm_handout":"Bekræft Udlevering",
          "lbl_The_article_barcode:":"Varens stregkode:",
          "lbl_handout_quantity_confirm_text": "Bekræft venligst at det valgte antal stemmer overens med det antal af varen som du skal til at udlevere.",
          "lbl_hand_out":"Udlevér",
          "lbl_handed_out_by":"Udleveret Af",
          "lbl_handed_out":"Udleveret",
          "lbl_picked_by":"Plukket Af",
          "lbl_hand_out_order": "Udlevér Ordre",
          "lbl_pickup_code":"Afhentningskode",
          "ok_button_label":"OK",
          "could_not_update_the_article_line":"Kunne ikke opdatere varelinjen.",
          "msg_could_not_remove_add_fee":"Kunne ikke fjerne/tilføje gebyr.",
          "order_is_unlocked":"Ordren er låst op",
          "msg_could_not_lock_unlock_order":"Kunne ikke låse/låse op for ordren.",
          "order_is_locked":"Ordren er låst",
          "msg_check_required_fields":"Check de påkrævede felter",
          "msg_error_found_could_not_mark_pick":"Fejl fundet, kunne ikke markere som plukket!",
          "msg_unable_to_pick_article":"Ikke muligt at plukke vare!",
          "msg_article_picked":"Vare plukket!",
          "additional_info":"Yderligere info",
          "btn_caption_check": "Check",
          "scan_or_enter_the_article_s_barcode":"indtast varens stregkode eller varenr.",
          "barcode_or_article_no":"Stregkode eller varenr.",
          "please_select_a_pickup_spot":"Vælg venligst et afhentningssted!",
          "select_not_selected_txt":"Ikke Valgt",
          "stock_level_at_order_time":"Lagerbeholdning på bestillingstidspunkt",
          "header_pick_article":"Pluk Vare",
          "btn_caption_undo":"Fortryd",
          "btn_caption_sold":"Udsolgt",
          "btn_caption_print":"Print",
          "lbl_select_printer":"Vælg Printer",
          "lbl_select_label_printer":"Label Printer",
          "lbl_select_celeveron_printer":"Package Tower Printer",
          "btn_caption_image":"Billede",
          "header_image":"Billede",
          "btn_caption_edit":"Ret",
          "btn_caption_pick":"Pluk",
          "lbl_quantity_picked":"Antal Plukket",
          "lbl_pickup_spot":"Afhentningssted",
          "lbl_article_no":"Art. No.",
          "lbl_price":"Pris",
          "lbl_ean":"EAN",
          "lbl_quantity":"Antal",
          "app_name":" Click&Collect",
          "lbl_TopNavHome":"Hjem",
          "copy_right": "Copy rights, All rights are reserved.",
          "version_heading": "Version:",
          "version_code":"1.0.0",
          "info_label":"Info",
          "login_btn_caption":"Log på",
          "logoff_btn_caption":"Log af",
          "available_store_heading": "Tilladte butikker",
          "action_search":"Søg",
          "header_orders":"Ordre",
          "breadcrumb_lbl_home":"Hjem",
          "breadcrumb_lbl_orders":"Ordre",
          "breadcrumb_lbl_order_detail":"Ordredetaljer",
          "tab_lbl_pending":"Afventer",
          "tab_lbl_ready":"Klar",
          "tab_lbl_cancelled":"Annulleret",
          "tab_lbl_Expired":"Udløbet",
          "placeholder_input_search_order":"Søg Ordre",
          "lbl_order_no":"Ordrenr.",
          "lbl_created":"Oprettet",
          "lbl_picked":"Plukket",
          "lbl_customer":"Kunde",
          "lbl_locked_by":"Låst Af:",
          "lbl_more_info": "Mere Info",
          "header_pick_articles":"Pluk Varer",
          "lbl_lock_order":"Lås Ordre",
          "lbl_remove_fee":"Fjern Gebyr",
          "lbl_order_details": "Ordredetaljer",
          "lbl_article_lines": "Varelinjer",
          "lbl_Mobile":"Mobil",
          "lbl_email":"Email",
          "lbl_expires":"Udløber",
          "lbl_status":"Status",

         
        }
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;