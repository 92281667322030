import axios, { AxiosResponse,AxiosRequestConfig } from 'axios';

//axios.defaults.baseURL = 'http://localhost:57712/api/v1';

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const CnC_API = axios.create({
  baseURL: `${document.getElementById('root')?.baseURI}api`
 // headers: { "CnC-Api-Key": "457203e7-49db-4307-a501-d9c5d99212a9" },
});

axios.interceptors.response.use(async (response) => {
  try {
    await sleep(1000);
    return response;
  } catch (error) {
    //console.log(error);
    return await Promise.reject(error);
  }
});

const responseBody = <T> (response:AxiosResponse<T>) => response.data;

const requests = {
    
    get: <T> (url: string, config? : AxiosRequestConfig) => CnC_API.get<T>(url,config).then(responseBody),
    post: <T> (url: string, body:{}, config? : AxiosRequestConfig) => CnC_API.post<T>(url,body,config).then(responseBody),
    put: <T> (url: string, body:{}, config? : AxiosRequestConfig) => CnC_API.put<T>(url,body,config).then(responseBody),
    patch: <T> (url: string, body:{}, config? : AxiosRequestConfig) => CnC_API.patch<T>(url,body,config).then(responseBody),
    delete: <T> (url: string, config? : AxiosRequestConfig) => CnC_API.delete<T>(url,config).then(responseBody),
    
}

const Invitations = {

  create:(body:{}, config? : AxiosRequestConfig) => requests.post<any>(`/Invitation/Request`, body),
  createBarRequest:(body:{}, config? : AxiosRequestConfig) => requests.post<any>(`/Invitation/BarRequest`, body),
  creatFamilyDayRegisteration:(body:{}, config? : AxiosRequestConfig) => requests.post<any>(`/Invitation/FamilyDayRegistration`, body),
  creatFamilyDayEventsRegisteration:(body:{}, config? : AxiosRequestConfig) => requests.post<any>(`/Invitation/FamilyDayEventsRegistration`, body),
  GetExpiryTimes:(PageName:string, config? : AxiosRequestConfig) => requests.get<any>(`/Invitation/GetExpiryTimes/${PageName}`),
  GetFamilyDayRegistration:(userId:string, config? : AxiosRequestConfig) => requests.get<any>(`/Invitation/GetFamilyDayRegistration/${userId}`),
  GetFamilyDayEventsParticipants:(config? : AxiosRequestConfig) => requests.get<any>(`/Invitation/GetFamilyDayEventsParticipants`),
  GetFamilyDayEventsParticipantsByUser:(userId:string,config? : AxiosRequestConfig) => requests.get<any>(`/Invitation/GetFamilyDayEventsParticipantsByUser/${userId}`),
  creatAnnualGraduationPartyRegisteration:(body:{}, config? : AxiosRequestConfig) => requests.post<any>(`/Invitation/AnnualGraduationPartyRegisteration`, body),
  GetAnnualGraduationPartyRegisteration:(userId:string, config? : AxiosRequestConfig) => requests.get<any>(`/Invitation/GetAnnualGraduationPartyRegisteration/${userId}`),
  GetAnnualGraduationPartyUser:(userId:string, config? : AxiosRequestConfig) => requests.get<any>(`/Invitation/GetAnnualGraduationPartyUser/${userId}`),
  GetGetAllAgpRegisterations: (config?: AxiosRequestConfig) => requests.get<any>(`/Invitation/GetAllAgpRegisterations`),
  //AllInUserInfo
  PostAllInUserInfo: (body: {}, config?: AxiosRequestConfig) => requests.post<any>(`/Invitation/PostAllInUserInfo`, body),
  
}


const baseApi = {
    Invitations,
    
}

export default baseApi;