import React, { useCallback, useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { AppContext } from '../Context'
import { useAccount, useMsal } from '@azure/msal-react'
import { UserProfile } from '../../_models/common/profile.model'
import { callMsGraph } from '../../_utils/MsGraphApiCall';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function SMC_UserInfoPage() {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userProfile, setUserProfile] = useState<null | UserProfile>(null);
  const [onPremisesSamAccountName, setOnPremisesSamAccountName] = useState('');
  const [mail,setmail] = useState('');
  const { formValues, handleChange, handleNext, variant, margin } = useContext(AppContext);
  const { userId, userName,userEmail,gender,telephone,date,adults,childrenCount,employeeTShirtSize } = formValues;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>();
  useEffect(() => {
    
     // console.log(account)
     fetchUserProfile();

  }, [account]);

  async function fetchUserProfile() {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            
              callMsGraph(response.accessToken).then((response) => {
              const profile: UserProfile = response as UserProfile;
              if (profile !== null || profile !== undefined) {
               // console.log(profile);

                setUserProfile(profile);
                setOnPremisesSamAccountName(profile.onPremisesSamAccountName);
                setmail(profile.mail);
                userId.value = profile.onPremisesSamAccountName;
                userName.value = profile.displayName;
                userEmail.value = profile.mail;
               // console.log(profile.onPremisesSamAccountName);
                  
                
              }
            });
          }
        });
    }
  }

  const handleDateChange = (vDate: Date | null) => {
    setSelectedDate(vDate);
    date.value = vDate?.toISOString();
  };

  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({userId,userName,userEmail,gender,telephone,date,adults,childrenCount,employeeTShirtSize }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues,userId,userName,userEmail,gender,telephone,date,adults,childrenCount,employeeTShirtSize]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="User ID"
            name="userId"
            placeholder="Enter User Id"
            value={onPremisesSamAccountName}
            onChange={handleChange}
            error={!!userId.error}
            helperText={userId.error}
            required={userId.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            fullWidth
            label="Name"
            name="userName"
            placeholder="Enter Name"
            value={account?.name}
            onChange={handleChange}
            error={!!userName.error}
            helperText={userName.error}
            required={userName.required}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="gender"
            value={gender.value}
            onChange={handleChange}
            error={!!gender.error}
            helperText={gender.error}
            required={gender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Birthday"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Telephone number "
            name="telephone"
            placeholder="Enter Telephone number "
            value={telephone.value}
            onChange={handleChange}
            error={!!telephone.error}
            helperText={telephone.error}
            required={telephone.required}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            variant={variant} 
            margin={margin}
            fullWidth
            label="Email"
            name="userEmail"
            placeholder="enter email"
            value={mail}
            onChange={handleChange}
            error={!!userEmail.error}
            helperText={userEmail.error}
            required={userEmail.required}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="employeeTShirtSize"
            value={employeeTShirtSize.value}
            onChange={handleChange}
            error={!!employeeTShirtSize.error}
            helperText={employeeTShirtSize.error}
            required={employeeTShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            variant={variant}
            margin={margin}
            fullWidth
            label="Number of parents"
            name="adults"
            placeholder=""
            value={adults.value}
            defaultValue={adults.value}
            onChange={handleChange}
            error={!!adults.error}
            helperText={adults.error}
            required={adults.required}
            inputProps={{ minLength: 1,maxLength: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            variant={variant}
            margin={margin}
            fullWidth
            label="Number of children"
            name="childrenCount"
            placeholder=""
            value={childrenCount.value}
            defaultValue={childrenCount.value}
            onChange={handleChange}
            error={!!childrenCount.error}
            helperText={childrenCount.error}
            required={childrenCount.required}
            inputProps={{ minLength: 1, maxLength: 5 }}
          />
        </Grid>
      </Grid>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="primary" disabled={isError()} onClick={!isError() ? handleNext : () => null} >
          Next
        </Button>
      </div>
    </>
  );
}
