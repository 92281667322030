import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import CloseInvitation from '../CloseInvitation';
import { StepsProvider } from '../Context';
import { DateTime } from "luxon";
import SommerCampStepForm from './SommerCampStepForm';
import baseApi from '../../_api/base.api';

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3)
    }
  }
}))

export default function SommerCampComponent() {
    
    const classes = useStyles();
    const [isExpire, setIsExpire] = useState(true);

    useEffect(() => {
      getExpiryTimes("SommerCamp");
    }, []);


    const getExpiryTimes = async (pageName: string) => {
      baseApi.Invitations.GetExpiryTimes(pageName).then((response: any) => {
        if (response) {
          console.log('response',response);
          //var expiry = response.find((x: { pageName: string; }) => x.pageName == "BarInvitation").expiry;
          var expiry = response.expiry;
          console.log('expiry',expiry);
          var currDateTime = DateTime.fromISO(DateTime.now().toISO());
          // var expireDate = DateTime.fromISO('2021-11-4');
          var expireDate = DateTime.fromISO(expiry);
          //console.log(expireDate >= currDateTime);
          if (expireDate >= currDateTime) {
            setIsExpire(false);
          }
        }
      });
    }

   if (isExpire)
     return (
       <>
           <CssBaseline />
           <main className={classes.layout}>
             <Paper className={classes.paper}>
               <CloseInvitation />
             </Paper>
           </main>
       </>
   );
   return (
     <>
       <StepsProvider>
         <CssBaseline />
         <main className={classes.layout}>
           <Paper className={classes.paper}>
             <SommerCampStepForm />
           </Paper>
         </main>
       </StepsProvider>
     </>
   );
}
