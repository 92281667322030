import React, { useContext } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { AppContext } from '../Context'
import baseApi from '../../_api/base.api';
import { useAccount, useMsal } from '@azure/msal-react'


export interface InvitationBarRequestModel {
  userId: string;
  userName: string;
  afdeling: string;
  email: string;
  telefonnummer: string;
  transport: string;
}
export default function BarInvitationConfirm() {

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { formValues, handleBack, handleNext} = useContext(AppContext);
  const { userId, userName,department,email,telephone,transport } = formValues;

  const handleSubmit = () => {
 

    let request:InvitationBarRequestModel = {
  
      userId : userId.value,
      userName : userName.value,
      afdeling : department.value,
      email : email.value,
      telefonnummer: telephone.value,
      transport : transport.value
     

    };

    baseApi.Invitations.createBarRequest(request).then(
      (response: any) => {
        //console.log(response);
        if (response) {
       
          handleNext();
        } 
      });
 
    
    // Show last component or success message
   // handleNext();
  }

  return (
    <>
      <List disablePadding>
        <ListItem>
          <ListItemText
            primary="User Id"
            secondary={userId.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="User Name"
            secondary={userName.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Department"
            secondary={department.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Email"
            secondary={email.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Phone"
            secondary={telephone.value || "Not Provided"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Do you want bus transport to Aarhus C?"
            secondary={transport.value || "Not Provided"}
          />
        </ListItem>
      </List>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="default" onClick={handleBack}>
          Back
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Save & Continue
        </Button>
      </div>
    </>
  );
}
