import React, { useContext, useEffect, useState } from 'react';
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { AppContext } from '../Context'
import AnnualGraduationPartyForm from './Agp_RegisterationForm';
import AnnualGraduationPartyConfirm from './Agp_Confirm';
import AnnualGraduationPartySuccess from './Agp_Success';
import { DateTime } from "luxon";
import { AnnualGraduationPartyModel } from '../../_models/invitation/AnnualGraduationParty.model';
import { UserProfile } from '../../_models/common/profile.model';
import baseApi from '../../_api/base.api';
import AnnualGraduationIntro from './Agp_Intro';
import AnnualGraduationAcknowledgment from './Agp_Acknowledgment';



// Step titles
const labels = ['Indledning', 'Tilmeldingsoplysninger', 'Betingelser', 'Bekræft og tilmeld'];

const handleSteps = (step: number, userProfileData: any) => {
  switch (step) {
    case 0:
      return <AnnualGraduationIntro userProfileData={userProfileData} />
    case 1:
      return <AnnualGraduationPartyForm userProfileData={userProfileData} />
    case 2:
      return <AnnualGraduationAcknowledgment userProfileData={userProfileData} />
    case 3:
      return <AnnualGraduationPartyConfirm userProfileData={userProfileData} />
    default:
      throw new Error('Unknown step')
  }
}

interface Props {
  userProfileData: any
}

export default function AnnualGraduationPartyStepForm({ userProfileData }: Props) {


  const { activeStep } = useContext(AppContext);
  const [userProfile, setUserProfile] = useState<UserProfile>(userProfileData);
  //const [annualGraduationPartyRegisteration, setAnnualGraduationPartyRegisteration] = useState<AnnualGraduationPartyModel>();

  useEffect(() => {

    //console.log(userProfileData);

    //const profile = localStorage.getItem("UserProfile");
    // const profile = userProfileData;
    // if (profile !== null && profile !== undefined) {

    //   //const userProfile: UserProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

    //   baseApi.Invitations.GetAnnualGraduationPartyRegisteration(profile.onPremisesSamAccountName).then(
    //     (response: AnnualGraduationPartyModel) => {
    //       if (response) {
    //         setAnnualGraduationPartyRegisteration(response);
    //       }
    //     });
    // }

  }, []);
  return (
    <div >
      <section className="content" >
        <div className="container-fluid">
          <>
            {activeStep === labels.length ? (
              <AnnualGraduationPartySuccess />
            ) : (
              <>
                <div style={{ margin: "30px 0 50px" }}>
                  <Typography variant="h4" align="center">
                    Danmarks største Dimissionsfest den 21. september   {DateTime.now().year}
                  </Typography>

                </div>
                <Stepper
                  activeStep={activeStep}
                  style={{ margin: "30px 0 15px" }}
                  alternativeLabel
                >
                  {labels.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {handleSteps(activeStep, userProfile)}
              </>
            )}
          </>
        </div>
      </section>
    </div>
  );
}


