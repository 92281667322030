import React, { useContext, useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../Context';
import FamilyDayForm from './FamilyDayForm';
import FamilyDayConfirm from './FamilyDayConfirm';
import FamilyDayInvitationSuccess from './FamilydaySuccess';
import { DateTime } from "luxon";
import { UserProfile } from '../../_models/common/profile.model';
import baseApi from '../../_api/base.api';
import { FamilyDayRegistrationModel } from "../../_models/invitation/FamilyDayRegistratoin.model";
import Familiedagcover from '../Familiedagcover.jpg'

// Step titles
const labels = ['Registration Form', 'Save & Confirmation'];

const handleSteps = (step: number, userregistration: any) => {

  switch (step) {
    case 0:
      return <FamilyDayForm familyDayRegistration={userregistration} />
    case 1:
      return <FamilyDayConfirm />
    default:
      throw new Error('Unknown step')
  }
}

export default function FamilyDayStepForm() {

  const { activeStep } = useContext(AppContext);
  const [userRegistration, setuserRegistration] = useState<FamilyDayRegistrationModel>();

  useEffect(() => {

    const profile = localStorage.getItem("UserProfile");
    if (profile !== null && profile !== undefined) {

      const userProfile: UserProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;

      baseApi.Invitations.GetFamilyDayRegistration(userProfile.onPremisesSamAccountName).then(
        (response: FamilyDayRegistrationModel) => {
          if (response) {
            setuserRegistration(response);
          }
        });
    }

  }, []);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <>
            {activeStep === labels.length ? (
              <FamilyDayInvitationSuccess />
            ) : (
              <>
                {/* <div style={{ margin: "30px 0 50px" }}>
                  <Typography variant="h4" align="center">
                    Family Day Registration {DateTime.now().year}
                  </Typography>

                </div> */}
                <Grid container  spacing={1}>
                  <Grid item xs={12}>
                    <img src={Familiedagcover} alt="" style={{ maxWidth: "100%", height: "auto" }}  ></img>
                  </Grid>
                </Grid>
                <Stepper
                  activeStep={activeStep}
                  style={{ margin: "30px 0 15px" }}
                  alternativeLabel
                >
                  {labels.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {handleSteps(activeStep, userRegistration)}
              </>
            )}
          </>
        </div>
      </section>
    </div>
  );
}
