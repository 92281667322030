import React, { useCallback, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
//import AddIcon from '@material-ui/icons/Add';
import { AppContext } from '../Context'
//import { List, Divider, ListItem, ListItemText, Fab } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function SMC_ChildrenInfoPage() {

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin,
    listChildren,
    addChildren
  } = useContext(AppContext);

  const [child1Date, setChild1Date] = React.useState<Date | null>(new Date());
  const [child2Date, setChild2Date] = React.useState<Date | null>(new Date());
  const [child3Date, setChild3Date] = React.useState<Date | null>(new Date());
  const [child4Date, setChild4Date] = React.useState<Date | null>(new Date());
  const [child5Date, setChild5Date] = React.useState<Date | null>(new Date());
  
  const {child1Name,child1Gender,child1Birthday,child1TShirtSize,child2Name,child2Gender,child2Birthday,child2TShirtSize,child3Name,child3Gender,child3Birthday,child3TShirtSize,child4Name,child4Gender,child4Birthday,child4TShirtSize,child5Name,child5Gender,child5Birthday,child5TShirtSize,} = formValues;

  const isError = useCallback(
    () =>
      Object.keys({child1Name,child1Gender,child1Birthday,child1TShirtSize,child2Name,child2Gender,child2Birthday,child2TShirtSize,child3Name,child3Gender,child3Birthday,child3TShirtSize,child4Name,child4Gender,child4Birthday,child4TShirtSize,child5Name,child5Gender,child5Birthday,child5TShirtSize,}).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues,child1Name,child1Gender,child1Birthday,child1TShirtSize,child2Name,child2Gender,child2Birthday,child2TShirtSize,child3Name,child3Gender,child3Birthday,child3TShirtSize,child4Name,child4Gender,child4Birthday,child4TShirtSize,child5Name,child5Gender,child5Birthday,child5TShirtSize,]
  );

  const handleChild1BirthdayChange = (vDate: Date | null) => {
    setChild1Date(vDate);
    child1Birthday.value = vDate?.toISOString();
  };

  const handleChild2BirthdayChange = (vDate: Date | null) => {
    setChild2Date(vDate);
    child2Birthday.value = vDate?.toISOString();
  };
  const handleChild3BirthdayChange = (vDate: Date | null) => {
    setChild3Date(vDate);
    child3Birthday.value = vDate?.toISOString();
  };
  const handleChild4BirthdayChange = (vDate: Date | null) => {
    setChild4Date(vDate);
    child4Birthday.value = vDate?.toISOString();
  };
  const handleChild5BirthdayChange = (vDate: Date | null) => {
    setChild5Date(vDate);
    child5Birthday.value = vDate?.toISOString();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Child No. 1
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="child1Name"
            placeholder=""
            value={child1Name.value}
            defaultValue={child1Name.value}
            onChange={handleChange}
            error={!!child1Name.error}
            helperText={child1Name.error}
            required={child1Name.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="child1Gender"
            value={child1Gender.value}
            onChange={handleChange}
            error={!!child1Gender.error}
            helperText={child1Gender.error}
            required={child1Gender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="child1TShirtSize"
            value={child1TShirtSize.value}
            onChange={handleChange}
            error={!!child1TShirtSize.error}
            helperText={child1TShirtSize.error}
            required={child1TShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="child1Date"
              label="Birthday"
              format="MM/dd/yyyy"
              value={child1Date}
              onChange={handleChild1BirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Child No. 2
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="child2Name"
            placeholder=""
            value={child2Name.value}
            defaultValue={child2Name.value}
            onChange={handleChange}
            error={!!child2Name.error}
            helperText={child2Name.error}
            required={child2Name.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="child2Gender"
            value={child2Gender.value}
            onChange={handleChange}
            error={!!child2Gender.error}
            helperText={child2Gender.error}
            required={child2Gender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="child2TShirtSize"
            value={child2TShirtSize.value}
            onChange={handleChange}
            error={!!child2TShirtSize.error}
            helperText={child2TShirtSize.error}
            required={child2TShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="secondParentDate"
              label="Birthday"
              format="MM/dd/yyyy"
              value={child2Date}
              onChange={handleChild2BirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Child No. 3
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="child3Name"
            placeholder=""
            value={child3Name.value}
            defaultValue={child3Name.value}
            onChange={handleChange}
            error={!!child3Name.error}
            helperText={child3Name.error}
            required={child3Name.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="child3Gender"
            value={child3Gender.value}
            onChange={handleChange}
            error={!!child3Gender.error}
            helperText={child3Gender.error}
            required={child3Gender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="child3TShirtSize"
            value={child3TShirtSize.value}
            onChange={handleChange}
            error={!!child3TShirtSize.error}
            helperText={child3TShirtSize.error}
            required={child3TShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="child3Date"
              label="Birthday"
              format="MM/dd/yyyy"
              value={child3Date}
              onChange={handleChild3BirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Child No. 4
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="child4Name"
            placeholder=""
            value={child4Name.value}
            defaultValue={child4Name.value}
            onChange={handleChange}
            error={!!child4Name.error}
            helperText={child4Name.error}
            required={child4Name.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="child4Gender"
            value={child4Gender.value}
            onChange={handleChange}
            error={!!child4Gender.error}
            helperText={child4Gender.error}
            required={child4Gender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="secondParentTShirtSize"
            value={child4TShirtSize.value}
            onChange={handleChange}
            error={!!child4TShirtSize.error}
            helperText={child4TShirtSize.error}
            required={child4TShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="child4Date"
              label="Birthday"
              format="MM/dd/yyyy"
              value={child4Date}
              onChange={handleChild4BirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
            Child No. 5
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TextField
            type="text"
            variant={variant}
            margin={margin}
            label="Name"
            name="child5Name"
            placeholder=""
            value={child5Name.value}
            defaultValue={child5Name.value}
            onChange={handleChange}
            error={!!child5Name.error}
            helperText={child5Name.error}
            required={child5Name.required}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Gender"
            name="child5Gender"
            value={child5Gender.value}
            onChange={handleChange}
            error={!!child5Gender.error}
            helperText={child5Gender.error}
            required={child5Gender.required}
          >
            <option value=""></option>
            <option value="Male">{"Male"}</option>
            <option value="Female">{"Female"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="T-Shirt Size"
            name="child5TShirtSize"
            value={child5TShirtSize.value}
            onChange={handleChange}
            error={!!child5TShirtSize.error}
            helperText={child5TShirtSize.error}
            required={child5TShirtSize.required}
          >
            <option value=""></option>
            <option value="S">{"S"}</option>
            <option value="M">{"M"}</option>
            <option value="L">{"L"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XL">{"XL"}</option>
            <option value="XXL">{"XXL"}</option>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="child5Date"
              label="Birthday"
              format="MM/dd/yyyy"
              value={child5Date}
              onChange={handleChild5BirthdayChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" disabled={isError()} onClick={!isError() ? handleNext : () => null} >
          Next
        </Button>
      </div>
    </>
  );
}
