import React, {  } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../logo.png';
import { useIsAuthenticated } from "@azure/msal-react";
import UserName from './UserName';
import SignInSignOutButton from './SignInSignOutButton';
import { useTranslation } from "react-i18next";



export default function Header()  {
    const isAuthenticated = useIsAuthenticated();
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng:any) => {
       // console.log(lng);
        i18n.changeLanguage(lng);
    };
    return (
      <React.Fragment>
        <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
          <div className="container">
            <NavLink to={"/Home"} className="navbar-brand">
              <img src={logo} alt="Logo" className="brand-image" />
              
            </NavLink>
                    <button
                        id="headerNavBarButton"  
              className="navbar-toggler order-1"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            {isAuthenticated && (
              <div
                className="collapse navbar-collapse order-3"
                id="navbarCollapse"
              >
                {/* Left navbar links */}
                {/* <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/Home" className="nav-link">
                      {t("lbl_TopNavHome")}
                    </Link>
                  </li>
                  
                </ul> */}
                {/* SEARCH FORM */}
              </div>
            )}
            {/* Right navbar links */}
            <div className="order-1 order-md-3">
              <ul className="navbar-nav navbar-no-expand ml-auto">
                <li className="nav-item" style={{ marginTop: "13px" }}>
                  <UserName />
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                        <i className="fas fa-th-large" />
                        </a> */}
                  <SignInSignOutButton />
                </li>
                
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
    
}
