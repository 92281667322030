import React, { useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3)
  },
  title: {
    marginTop: 30
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }
}))

export default function AnnualGraduationPartySuccess() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.box}>

        <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 18 }}>Tak for din tilmelding.</p>
        <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 16 }}>Vi glæder os til at se dig den 21. september 2023 kl. 11.00 – 15.30 til Danmarks største Dimissionsfest.</p>
        <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 16 }}>Hold øje med din mail, hvor du vil modtage en bekræftelse på din tilmelding.</p>
        <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 16 }}>Du får billetten tilsendt 14 dage inden festen.</p>
        <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 16 }}>Har du spørgsmål eller ændringer til din tilmelding kan du sende en mail til:</p>
        <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 16 }}><a href="mailto: Dimission@sallinggroup.com">Dimission@sallinggroup.com</a></p>


      </div>
    </>
  )
}
