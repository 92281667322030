import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Badge, Grid, Paper, Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import baseApi from '../../_api/base.api';
import { UserProfile } from "../../_models/common/profile.model";
import { AnnualGraduationPartyRegisterations } from "../../_models/invitation/AnnualGraduationParty.model";
import Loader from "../common/loader/loader";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1500 + theme.spacing(2) * 2)]: {
      width: 1500,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1500 + theme.spacing(1) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(1)
    }
  },
  table: {
    minWidth: 650,
  },
}))

const employees = [
  {
    name: "John Doe",
    email: "johndoe@example.com",
    phone: "555-555-5555",
  },
  {
    name: "Jane Smith",
    email: "janesmith@example.com",
    phone: "555-555-5556",
  },
  {
    name: "Bob Johnson",
    email: "bobjohnson@example.com",
    phone: "555-555-5557",
  },
  {
    name: "Bob Johnson",
    email: "bobjohnson@example.com",
    phone: "555-555-5557",
  },
  {
    name: "John Doe",
    email: "johndoe@example.com",
    phone: "555-555-5555",
  },
  {
    name: "Jane Smith",
    email: "janesmith@example.com",
    phone: "555-555-5556",
  },

];

export default function AgpRegisterationsList() {

  const classes = useStyles();
  const [studentRegisterations, setStudentRegisterations] = useState<AnnualGraduationPartyRegisterations[]>([]);
  const [managersRegisterations, setManagersRegisterations] = useState<AnnualGraduationPartyRegisterations[]>([]);
  const [loadingProgress, setLoadingProgress] = useState(false);

  useEffect(() => {

    const profile = localStorage.getItem("UserProfile");
    let userProfile: UserProfile = {} as UserProfile;
    userProfile = profile !== null ? JSON.parse(profile) : {} as UserProfile;
    function fetchRegistration(userId: string) {
      setLoadingProgress(true);
      baseApi.Invitations.GetGetAllAgpRegisterations().then(
        (response: AnnualGraduationPartyRegisterations[]) => {
          if (response) {
            setLoadingProgress(false);
            if (response !== null) {
              //console.log(response);
              let studentsRegisterations = response.filter(x => x.role == "Student");
              if (studentsRegisterations != null) {
                setStudentRegisterations(studentsRegisterations);
              }

              let managersData = response.filter(x => x.role !== "Student");
              if (managersData != null) {
                setManagersRegisterations(managersData);
              }

            }

          }
        });
    }
    //fetchRegistration('expimb');
    fetchRegistration(userProfile.onPremisesSamAccountName);
  }, []);

  if (loadingProgress) return <Loader loading={loadingProgress}></Loader>

  return (
    <section className="content" style={{marginTop:"20px"}}>
      <div className="container-fluid">
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab key="PendingTab">
              <div style={{ textAlign: "center" }}>
              <i className="fas fa-users"></i>{" "}
                {"Students Registerations"}
              </div>
            </Tab>
            <Tab key="ReadyTab">
              <div style={{ textAlign: "center" }}>
              <i className="fas fa-users"></i>{" "}
                {"Managers Registerations"}

              </div>
            </Tab>

          </TabList>
          <TabPanel key="Student">
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Telephone</TableCell>
                          <TableCell>Companion Name</TableCell>
                          <TableCell>Companion Email</TableCell>
                          <TableCell>Companion Telephone</TableCell>
                          <TableCell align="center">Registeration Status</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentRegisterations.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.userName}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.telephone}</TableCell>
                            <TableCell>{row.companionUserName ? row.companionUserName : "-"}</TableCell>
                            <TableCell>{row.companionEmail ? row.companionEmail : "-"}</TableCell>
                            <TableCell>{row.companionTelephone ? row.companionTelephone : "-"}</TableCell>
                            <TableCell align="center">{row.userWarehouseNo ? <Badge badgeContent={"Submitted"} color="primary"></Badge> : <Badge badgeContent={"Pending"} color="error"></Badge>}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            </Paper>
          </TabPanel>
          <TabPanel key="Manager">
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Telephone</TableCell>
                          <TableCell align="center">Registeration Status</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {managersRegisterations.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.userName}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.telephone}</TableCell>
                            <TableCell align="center">{row.userWarehouseNo ? <Badge badgeContent={"Submitted"} color="primary"></Badge> : <Badge badgeContent={"Pending"} color="error"></Badge>}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>

          </TabPanel>

        </Tabs>

      </div>
    </section>

  );
}
