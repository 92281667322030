import React, { Component } from 'react'
import { useTranslation } from "react-i18next";
export default function Footer () {
    const { t, i18n } = useTranslation();
return (
            
            <footer className="main-footer">
                    <strong>{t("copy_right")}</strong>
                    <div className="float-right d-none d-sm-inline-block">
                        <b>{t("version_heading")}</b> {t("version_code")}
                    </div>
                </footer>

        )
}
