import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { AppContext } from '../Context';
import SMC_UserInfoPage from './SMC_UserInfoPage';
import SMC_ParentInfoPage from './SMC_ParentInfoPage';
import SMC_ChildrenInfoPage from './SMC_ChildrenInfoPage';
import SMC_Acknowledgment from './SMC_Acknowledgment';
import SMC_GeneralComments from './SMC_GeneralComments';
import SommerCampSuccess from './SommerCampSuccess';

// Step titles
const labels = ['User Info', 'Parent Details', 'Children Details', 'Acknowledgment', 'Comments & Save'];

const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <SMC_UserInfoPage/>
    case 1:
      return <SMC_ParentInfoPage />
    case 2:
      return <SMC_ChildrenInfoPage />
    case 3:
      return <SMC_Acknowledgment />
    case 4:
      return <SMC_GeneralComments />
    default:
      throw new Error('Unknown step')
  }
}


export default function SommerCampStepForm() {

    const { activeStep } = useContext(AppContext);
 
    return (
      <div>
        
        <section className="content">
          <div className="container-fluid">
            <>
              {activeStep === labels.length ? (
                <SommerCampSuccess />
                
              ) : (
                <>
                  <Stepper
                    activeStep={activeStep}
                    style={{ margin: "30px 0 15px" }}
                    alternativeLabel
                  >
                    {labels.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {handleSteps(activeStep)}
                </>
              )}
            </>
          </div>
        </section>
      </div>
    );
}
