import React, { useContext, useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { AppContext } from '../Context'
import baseApi from '../../_api/base.api';
import { useAccount, useMsal } from '@azure/msal-react';
import { useAlert } from "react-alert";
import Loader from "../common/loader/loader";

interface Props {
  userProfileData: any
}

export default function AnnualGraduationPartyConfirm({userProfileData}:Props) {

  const { accounts } = useMsal();
  const alert = useAlert();
  const account = useAccount(accounts[0] || {});
  const [loadingProgress, setLoadingProgress] = useState(false);
  const { formValues, handleBack, handleNext } = useContext(AppContext);
  const { userId, userName, department, email, agp_UserRole, telephone, agp_legalAcknowledgment, agp_UserTitle, agp_UserWarehouseNo, agp_UserDietaryPref, agp_CompanionUserName, agp_CompanionEmail, agp_CompanionTelephone, agp_CompanionInvitedBy, agp_CompanionRelationship, agp_CompanionDietaryPref } = formValues;

  useEffect(() => {
    //console.log(agp_UserRole.value);
    

  }, [])

  const handleSubmit = () => {


    let request: any = {

      Id: 0,
      userId: userId.value,
      title: agp_UserTitle.value,
      userName: userName.value,
      department: department.value,
      email: email.value,
      telephone: telephone.value,
      userWarehouseNo: agp_UserWarehouseNo.value,
      userDietaryPref: agp_UserDietaryPref.value,
      role: agp_UserRole.value,
      companionUserName: agp_CompanionUserName.value,
      companionEmail: agp_CompanionEmail.value,
      companionTelephone: agp_CompanionTelephone.value,
      companionInvitedBy: agp_CompanionInvitedBy.value,
      companionRelationship: agp_CompanionRelationship.value,
      companionDietaryPref: agp_CompanionDietaryPref.value,
      legalStatmentAcknowledgment: agp_legalAcknowledgment.value
    };

    if (agp_legalAcknowledgment.value !== null && agp_legalAcknowledgment.value !== null && agp_legalAcknowledgment.value === "Ja") {
      setLoadingProgress(true);
      baseApi.Invitations.creatAnnualGraduationPartyRegisteration(request).then(
        (response: any) => {
          setLoadingProgress(false);
          //console.log(response);
          if (response) {

            handleNext();
          }
        }).catch((reason: any) => {
          console.log('Error Occured.')
        });

    }
    else {
      alert.error('Legal Statment Acknowledgment is required.!');
    }



    // Show last component or success message
    // handleNext();
  }
  if (loadingProgress) return <Loader loading={loadingProgress}></Loader>
  return (
    <>
      <List subheader={<ListSubheader>Dine oplysninger</ListSubheader>} disablePadding>
        <ListItem>
          <ListItemText
            primary="Navn"
            secondary={userName.value || "Ikke oplyst"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Stillingsbetegnelse"
            secondary={agp_UserTitle.value || "Ikke oplyst"}
          />
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemText
            primary="Lønnummer"
            secondary={userId.value || "Ikke oplyst"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Email"
            secondary={email.value || "Ikke oplyst"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Telefonnummer"
            secondary={telephone.value || "Ikke oplyst"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Varehusnr./Butiksnr"
            secondary={agp_UserWarehouseNo.value || "Ikke oplyst"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Diæt præferencer"
            secondary={agp_UserDietaryPref.value || "Ikke oplyst"}
          />
        </ListItem>
      </List>
      {agp_UserRole.value == 'Student' ?
        <List subheader={<ListSubheader>Oplysninger på ledsager</ListSubheader>} disablePadding>
          <ListItem>
            <ListItemText
              primary="Navn"
              secondary={agp_CompanionUserName.value || "Ikke oplyst"}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Email"
              secondary={agp_CompanionEmail.value || "Ikke oplyst"}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Telefonnummer"
              secondary={agp_CompanionTelephone.value || "Ikke oplyst"}
            />
          </ListItem>
          {/* <Divider />
          <ListItem>
            <ListItemText
              primary="Inviteret af"
              secondary={agp_CompanionInvitedBy.value || "Ikke oplyst"}
            />
          </ListItem> */}
          <Divider />
          <ListItem>
            <ListItemText
              primary="Relation til Ledsager"
              secondary={agp_CompanionRelationship.value || "Ikke oplyst"}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Diæt præferencer"
              secondary={agp_CompanionDietaryPref.value || "Ikke oplyst"}
            />
          </ListItem>
        </List>
        : null
      }
      < div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="default" onClick={handleBack}>
          Tilbage
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Bekræft og tilmeld
        </Button>
      </div>
    </>
  );
}
