import React, { useEffect } from 'react';
import './App.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import { Switch, Route, useHistory } from 'react-router';
import { PageLayout } from './components/layout/PageLayout';
import { CustomNavigationClient } from './_utils/NavigationClient';
import { IPublicClientApplication } from '@azure/msal-browser';
import InvitationComponent from './components/invitation/InvitationComponent';
import BarInvitationComponent from './components/bar-invitation/BarInvitationComponent';
import SommerCampComponent from './components/sommer-camp/SommerCampComponent';
import FamilyDayComponent from './components/familyday-invitation/FamilyDayComponent'
import FamilyDayEventsComponent from './components/familyday-events-registeration/FamilyDayEventsComponent';
import AnnualGraduationPartyComponent from './components/annual-graduation-party/Agp_Component'
import { useAccount, useMsal } from '@azure/msal-react';
import { callMsGraph } from './_utils/MsGraphApiCall';
import { UserProfile } from './_models/common/profile.model'
import AgpRegisterationsList from './components/annual-graduation-party/Agp_RegisterationsList';
import AllInUserInfoComponent from './components/AllInUserInfo/AllInUserInfoComponent';



type AppProps = {
    msalInstance: IPublicClientApplication
};
function App({ msalInstance }: AppProps) {

    const history = useHistory();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigationClient = new CustomNavigationClient(history);
    msalInstance.setNavigationClient(navigationClient);

    useEffect(() => {

        async function fetchUserProfile(account: any) {
            if (account) {
                instance
                    .acquireTokenSilent({
                        scopes: ["User.Read"],
                        account: account,
                    })
                    .then((response) => {
                        if (response) {
                            callMsGraph(response.accessToken).then((response) => {
                                const profile: UserProfile = response as UserProfile;
                                if (profile !== null && profile !== undefined) {
                                    //console.log(profile);
                                    localStorage.setItem("UserProfile", JSON.stringify(profile));
                                }
                            });
                        }
                    })
            }
        }

        fetchUserProfile(account);

    }, [account]);

    return (
        <div className="wrapper">
            <React.Fragment>
                <Header />
                <PageLayout >
                    <div className="content-wrapper" >
                        <Pages />
                    </div>
                </PageLayout>
                <Footer />
            </React.Fragment>
        </div>
    );
}

function Pages() {
    return (
        <Switch>
            <Route path={`/Invitation`} component={InvitationComponent}></Route>
            <Route path={`/BarInvitation`} component={BarInvitationComponent}></Route>
            <Route path={`/SommerCamp`} component={SommerCampComponent}></Route>
            <Route path={`/FamilyDaySignUp`} component={FamilyDayComponent}></Route>
            <Route path={`/FamilyDayEventsRegisteration`} component={FamilyDayEventsComponent}></Route>
            <Route path={`/AnnualGraduationPartyRegisteration`} component={AnnualGraduationPartyComponent}></Route>
            <Route path={`/AgpRegisterationsList`} component={AgpRegisterationsList}></Route>
            <Route path={`/AllInUserInfo`} component={AllInUserInfoComponent}></Route>
        </Switch>
    );
}

export default App;
